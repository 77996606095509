
import { defineComponent, PropType } from "vue";
import { Category, File } from "@/utils";
import { ContractDocTitles } from "@/utils/consts/contracts";
import { FundingDocTitles } from "@/utils/consts/fundings";
import { BACKEND_BASE_URL } from "@/utils/httpsApi";
import { logError, logInfo } from "@/utils/systemLog";

export default defineComponent({
  name: "RowUploadDocument",
  props: {
    activeCategory: {
      type: String as PropType<Category>,
      default: "contracts",
    },
    selectedRow: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedFile: null,
      documentType: null,
    } as {
      selectedFile: null | File;
      documentType: null | string; // TODO
    };
  },
  computed: {
    contractDocTypes() {
      return ContractDocTitles;
    },
    fundingDocTypes() {
      return FundingDocTitles;
    },
  },
  methods: {
    async upload() {
      try {
        // eslint-disable-next-line prefer-const
        let formData = new FormData();
        formData.append("file", this.selectedFile || ""); // TODO
        formData.append("category", this.documentType || ""); // TODO

        // TODO
        const response = await fetch(
          `${BACKEND_BASE_URL}/${this.activeCategory}/${this.selectedRow}/document`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("auth-token")}`,
            },
            body: formData,
          }
        );

        const responsePayload = await response.json();

        if (response.status >= 400) {
          throw new Error(responsePayload.message);
        }

        const systemLogPayload = {
          category: this.activeCategory,
          id: this.selectedRow,
          file: (this.selectedFile as File).name,
          type: this.documentType,
        };

        this.selectedFile = null;
        this.documentType = null;

        this.$emit("upload", false);

        await logInfo("Uploaded file", systemLogPayload);
      } catch (e) {
        this.$emit("upload", true);

        await logError(
          "file_upload_failure",
          `${(e as Error).name}: ${(e as Error).message}`,
          {
            category: this.activeCategory,
            id: this.selectedRow,
            file: (this.selectedFile as File).name,
            type: this.documentType,
            error: JSON.stringify(e),
          }
        );
      }
    },
  },
});
