
import { defineComponent } from "vue";

export default defineComponent({
  name: "OmniSearch",
  data() {
    return {
      query: "",
    };
  },
  methods: {
    search() {
      this.$emit("search", this.query.trim().toLowerCase());
    },
    clear() {
      this.query = "";

      this.search();
    },
  },
});
