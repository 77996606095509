
import { defineComponent, PropType } from "vue";
import { FundingStatusCode as StatusCode } from "@pentech-web/pt-types/bnpl/statusCodes";

export default defineComponent({
  name: "BnplFundingFlow",
  props: {
    currentStatusCode: {
      type: String as PropType<StatusCode>,
      required: true,
    },
  },
  methods: {
    update(statusCode: StatusCode) {
      this.$emit("update", statusCode);
    },
  },
});
