
import { defineComponent } from "vue";

import store from "@/store";

import CurrencyTag, { TagColor } from "@/components/CurrencyTag.vue";
import Notification from "@/components/Notification.vue";
import CreateTransactionModal from "@/components/orgs/CreateTransactionModal.vue";

import { EmptyObject, formatDate, formatTime } from "@/utils";
import { Tables, TransactionWithSourceBalance } from "@/utils/types";
import { getBalance, getTransactions } from "@/utils/httpsEndpoints";
import {
  getTransactionSubject,
  getUsageByTransactionSubjectProp,
  groupTransactions,
} from "@/utils/transactionGroups";
import { TransactionSubjects } from "@/utils/consts/transactionSubjects";
import { logError } from "@/utils/systemLog";

export default defineComponent({
  name: "Balance",
  components: { CurrencyTag, CreateTransactionModal, Notification },
  props: {
    balanceId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,
      displayIds: false,
      modalIsOpen: false,
      highlightedTransactionId: this.$route.query.highlight as string,
      org: {} as Tables["orgs"] | EmptyObject,
      balance: {} as Tables["vw_balances"] | EmptyObject,
      transactionGroups: {} as ReturnType<typeof groupTransactions>,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    formatDate,
    formatTime,
    formatTransaction(key: TransactionSubjects): string {
      return getTransactionSubject(key)?.label ?? "-";
    },
    async loadData() {
      this.isLoaded = false;

      try {
        this.balance = (await getBalance(this.balanceId)) ?? {};
        this.transactionGroups = groupTransactions(
          (await getTransactions(this.balanceId)) ?? []
        );

        this.org =
          store.state.orgs.find((o) => o.id === this.balance.org_id) ?? {};
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        await logError(
          "postgrest/getting_balance_or_transactions_failed",
          `${(e as Error).name}: ${(e as Error).message}`,
          {
            balanceId: this.balanceId,
            error: JSON.stringify(e),
          }
        );
      }

      await store.loadData("transactionSubjects");
      await store.loadData("fundings");
      await store.loadData("bnplFundings");
      await store.loadData("contracts");
      await store.loadData("bnplContracts");

      this.isLoaded = true;
    },
    onModalClose() {
      this.modalIsOpen = false;
      this.loadData();
    },
    currencyTagColor(transaction: Tables["transactions"]): TagColor {
      if (
        ["bank_transfer_out", "debtor_deposit"].includes(transaction.subject)
      ) {
        return "warning";
      }
      if (transaction.amount_gross < 0.01 && transaction.amount_gross > -0.01) {
        return "light";
      }
      return transaction.amount_gross > 0 ? "success" : "danger";
    },
    getBalanceUsage(transactions: TransactionWithSourceBalance[]): number {
      return getUsageByTransactionSubjectProp(
        "does_affect_balance",
        transactions
      );
    },
    getLimitUsage(transactions: TransactionWithSourceBalance[]): number {
      return Math.abs(
        getUsageByTransactionSubjectProp("does_affect_limit", transactions)
      );
    },
    getFundingStatus(transaction: Tables["transactions"]): string {
      if (transaction.funding_id) {
        return (
          store.getPropOfFunding("status_code", transaction.funding_id) ?? "-"
        );
      }
      if (transaction.bnpl_funding_id) {
        return (
          store.getPropOfBnplFunding(
            "status_code",
            transaction.bnpl_funding_id
          ) ?? "-"
        );
      }
      return "-";
    },
    getFundingInvoiceNumber(transaction: Tables["transactions"]): string {
      if (transaction.funding_id) {
        return (
          store.getPropOfFunding("invoice_no", transaction.funding_id) ?? "-"
        );
      }
      if (transaction.bnpl_funding_id) {
        return (
          store.getPropOfBnplFunding(
            "invoice_no",
            transaction.bnpl_funding_id
          ) ?? "-"
        );
      }
      return "-";
    },
    getContractStatus(transaction: Tables["transactions"]): string {
      if (transaction.contract_id) {
        return (
          store.getPropOfContract("status_code", transaction.contract_id) ?? "-"
        );
      }
      if (transaction.bnpl_contract_id) {
        return (
          store.getPropOfBnplContract(
            "status_code",
            transaction.bnpl_contract_id
          ) ?? "-"
        );
      }
      return "-";
    },
    getContractDebtor(transaction: Tables["transactions"]): string {
      if (transaction.contract_id) {
        return (
          store.getPropOfContract("debtor_org_name", transaction.contract_id) ??
          "-"
        );
      }
      if (transaction.bnpl_contract_id) {
        return (
          store.getPropOfBnplContract(
            "debtor_short_name",
            transaction.bnpl_contract_id
          ) ?? "-"
        );
      }
      return "-";
    },
  },
});
