
import { defineComponent, PropType } from "vue";

import CurrencyTag from "@/components/CurrencyTag.vue";
import {
  ContractDataGridColumns,
  ContractStatuses,
} from "@/utils/consts/contracts";
import {
  FundingDetailsColumns,
  FundingStatuses,
} from "@/utils/consts/fundings";
import {
  ContractDataGridColumns as BnplContractDataGridColumns,
  ContractStatuses as BnplContractStatuses,
} from "@/utils/consts/bnpl/contracts";
import {
  FundingDataGridColumns as BnplFundingDataGridColumns,
  FundingStatuses as BnplFundingStatuses,
} from "@/utils/consts/bnpl/fundings";
import {
  CreditorDataGridColumns as BnplCreditorDataGridColumns,
  CreditorStatuses as BnplCreditorStatuses,
} from "@/utils/consts/bnpl/creditors";
import {
  DebtorDataGridColumns as BnplDebtorDataGridColumns,
  DebtorStatuses as BnplDebtorStatuses,
} from "@/utils/consts/bnpl/debtors";

import {
  Category,
  formatContractRow,
  formatFundingRow,
  formatValue,
  getValue,
  getStatus,
  formatBnplContractRow,
  formatBnplFundingRow,
  StatusCode,
  formatBnplCreditorRow,
  formatBnplDebtorRow,
  FormattedData,
} from "../../utils";
import { Tables } from "@/utils/types";

export default defineComponent({
  name: "RowDetails",
  components: { CurrencyTag },
  props: {
    data: {
      type: Object as PropType<
        | Tables["vw_contract_with_orgs"]
        | Tables["vw_funding_with_orgs"]
        | Tables["vw_bnpl_contract_with_orgs"]
        | Tables["vw_bnpl_funding_with_orgs"]
        | Tables["vw_bnpl_creditor_with_org"]
        | Tables["vw_bnpl_debtor_with_org"]
      >,
      required: true,
    },
    activeCategory: {
      type: String as PropType<Category>,
      required: true,
    },
  },

  computed: {
    columns() {
      switch (this.activeCategory) {
        case "contracts":
        default:
          return ContractDataGridColumns;
        case "fundings":
          return FundingDetailsColumns;
        case "bnplContracts":
          return BnplContractDataGridColumns;
        case "bnplFundings":
          return BnplFundingDataGridColumns;
        case "bnplCreditors":
          return BnplCreditorDataGridColumns;
        case "bnplDebtors":
          return BnplDebtorDataGridColumns;
      }
    },
    formattedData() {
      switch (this.activeCategory) {
        case "contracts":
        default:
          return formatContractRow(
            this.data as Tables["vw_contract_with_orgs"]
          );
        case "fundings":
          return formatFundingRow(this.data as Tables["vw_funding_with_orgs"]);
        case "bnplContracts":
          return formatBnplContractRow(
            this.data as Tables["vw_bnpl_contract_with_orgs"]
          );
        case "bnplFundings":
          return formatBnplFundingRow(
            this.data as Tables["vw_bnpl_funding_with_orgs"]
          );
        case "bnplCreditors":
          return formatBnplCreditorRow(
            this.data as Tables["vw_bnpl_creditor_with_org"]
          );
        case "bnplDebtors":
          return formatBnplDebtorRow(
            this.data as Tables["vw_bnpl_debtor_with_org"]
          );
      }
    },
  },
  methods: {
    getValue,
    formatValue,
    // TODO do it with a computed property like in Checkboxes
    getStatusTitle(statusCode: string | null) {
      if (!statusCode) {
        return "";
      }

      const status = getStatus(statusCode as StatusCode, this.activeCategory);

      switch (this.activeCategory) {
        case "contracts":
        default:
          if (!status) {
            return "Contract Generated"; // to handle leaving boundaries of sfondo
          }

          return ContractStatuses[status].title;

        case "fundings":
          if (!status) {
            return "Payback Overdue"; // to handle FF-13 => FP-20 edge case (FP-20 is NOT in FundingStatuses), we return this string instead of undefined
          }

          return FundingStatuses[status].title;

        case "bnplContracts":
          if (!status) {
            return "Accepted";
          }

          return BnplContractStatuses[status].title;

        case "bnplFundings":
          if (!status) {
            return "Debtor Payback";
          }

          return BnplFundingStatuses[status].title;

        case "bnplCreditors":
          if (!status) {
            return "Registered";
          }

          return BnplCreditorStatuses[status].title;

        case "bnplDebtors":
          if (!status) {
            return "Registered";
          }

          return BnplDebtorStatuses[status].title;
      }
    },
    click(key: keyof FormattedData) {
      this.$router.push(
        `/orgs/${
          /* bnplCreditor/bnplDebtor's creditor_org_id/debtor_id is a reference to org's id field */
          /* bnplContract/bnplFunding's creditor_org_id/debtor_id is a reference to bnpl creditor/debtor's id field */

          /* that's why we use 'orgs' orgType for bnplCreditor/bnplDebtor and */
          /* 'bnplCreditors'/'bnplDebtors' orgType for only bnplContract/bnplFunding */

          // eslint-disable-next-line no-nested-ternary
          ["bnplContracts", "bnplFundings"].includes(this.activeCategory)
            ? key === "creditor_org"
              ? "bnplCreditors"
              : "bnplDebtors"
            : "orgs"
        }/${
          key === "creditor_org"
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (this.formattedData as any).creditor_org_id // TODO fix formattedData typing
            : // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (this.formattedData as any).debtor_id // TODO fix formattedData typing
        }/view`
      );
    },
  },
});
