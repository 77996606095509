import { FundingStatusCode as Status } from "@pentech-web/pt-types/bnpl/statusCodes";

/*
  'BFC-10': created (limit reserved)
  'BFC-11': finalize_requested
  'BFC-12': cancelled (free limit)
  'BFP-10': upsert_to_factor
  'BFP-90': auto_ko
  'BFP-91': limit_rejected

  'BFF-10': received_by_factor
  'BFF-90': rejected_by_factor
  'BFF-11': pending_payout
  'BFF-12': paid
  'BFF-13': payment_received
  'BFF-14': debtor_payback
*/

const RECEIVED_BY_FACTOR = "Received By Factor";
const PENDING_PAYOUT = "Pending Payout";
const PAID = "Paid";
const PAYMENT_RECEIVED = "Payment Received";
const DEBTOR_PAYBACK = "Debtor Payback";
const REJECTED = "Rejected";

export const FundingStatusTitles = {
  received_by_factor: RECEIVED_BY_FACTOR,
  pending_payout: PENDING_PAYOUT,
  paid: PAID,
  payment_received: PAYMENT_RECEIVED,
  debtor_payback: DEBTOR_PAYBACK,
  rejected: REJECTED,
} as const;

export const FundingDocTitles = {
  invoice: "Számla",
} as const;

export const FundingDataGridColumns = {
  created_at: "Létrehozva",
  updated_at: "Utoljára módosítva",
  amount: "Számla összege",
  issue_date: "Kiállítás dátuma",
  due_date: "Lejárat dátuma",
  invoice_no: "Számla sorszáma",
  creditor_org: "Creditor cég", // creditor_org_name + creditor_org_tax_number
  debtor_org: "Debtor cég", // debtor_org_name + debtor_org_tax_number
} as const;

export const FundingStatuses: {
  [key in string]: {
    ptStatusCodes: Status[];
    title: string;
  };
} = {
  received_by_factor: {
    ptStatusCodes: ["BFF-10"],
    title: RECEIVED_BY_FACTOR,
  },
  pending_payout: {
    ptStatusCodes: ["BFF-11"],
    title: PENDING_PAYOUT,
  },
  paid: {
    ptStatusCodes: ["BFF-12"],
    title: PAID,
  },
  payment_received: {
    ptStatusCodes: ["BFF-13"],
    title: PAYMENT_RECEIVED,
  },
  debtor_payback: {
    ptStatusCodes: ["BFF-14"],
    title: DEBTOR_PAYBACK,
  },
  rejected: {
    ptStatusCodes: ["BFF-90"],
    title: REJECTED,
  },
};
