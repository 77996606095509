import dayjs from "dayjs";
import { Base64 } from "js-base64";
import { StatusCode as ContractStatus } from "@pentech-web/pt-types/contract/status";
import { StatusCode as FundingStatus } from "@pentech-web/pt-types/funding/status";
import {
  ContractStatusCode as BnplContractStatus,
  FundingStatusCode as BnplFundingStatus,
  CreditorStatusCode as BnplCreditorStatus,
  DebtorStatusCode as BnplDebtorStatus,
} from "@pentech-web/pt-types/bnpl/statusCodes";

import { Tables } from "./types";
import { ContractStatuses, ContractStatusTitles } from "./consts/contracts";
import { FundingStatuses, FundingStatusTitles } from "./consts/fundings";
import {
  ContractStatuses as BnplContractStatuses,
  ContractStatusTitles as BnplContractStatusTitles,
} from "./consts/bnpl/contracts";
import {
  FundingStatuses as BnplFundingStatuses,
  FundingStatusTitles as BnplFundingStatusTitles,
} from "./consts/bnpl/fundings";
import {
  CreditorStatuses as BnplCreditorStatuses,
  CreditorStatusTitles as BnplCreditorStatusTitles,
} from "./consts/bnpl/creditors";
import {
  DebtorStatuses as BnplDebtorStatuses,
  DebtorStatusTitles as BnplDebtorStatusTitles,
} from "./consts/bnpl/debtors";
import { BACKEND_BASE_URL } from "./httpsApi";
// eslint-disable-next-line import/no-cycle
import { logInfo } from "./systemLog";

export type StatusCode =
  | ContractStatus
  | FundingStatus
  | BnplContractStatus
  | BnplFundingStatus
  | BnplCreditorStatus
  | BnplDebtorStatus;

export const formatTime = (string: string): string =>
  dayjs(string).format("YYYY.MM.DD. HH:mm:ss");
export const formatDate = (string: string): string =>
  dayjs(string).format("YYYY.MM.DD.");
export const formatPd = (value: number): string => `${value * 100}%`;

export type Category =
  | "contracts"
  | "fundings"
  | "bnplContracts"
  | "bnplFundings"
  | "bnplCreditors"
  | "bnplDebtors";

export const getStatus = (
  statusCode: StatusCode,
  category: Category
): undefined | string => {
  let statusEntryObject;

  switch (category) {
    case "contracts":
    default:
      statusEntryObject = Object.entries(ContractStatuses).find(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, statusEntry]) =>
          statusEntry.ptStatusCodes.includes(statusCode as ContractStatus)
      );
      break;

    case "fundings":
      statusEntryObject = Object.entries(FundingStatuses).find(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, statusEntry]) =>
          statusEntry.ptStatusCodes.includes(statusCode as FundingStatus)
      );
      break;

    case "bnplContracts":
      statusEntryObject = Object.entries(BnplContractStatuses).find(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, statusEntry]) =>
          statusEntry.ptStatusCodes.includes(statusCode as BnplContractStatus)
      );
      break;

    case "bnplFundings":
      statusEntryObject = Object.entries(BnplFundingStatuses).find(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, statusEntry]) =>
          statusEntry.ptStatusCodes.includes(statusCode as BnplFundingStatus)
      );
      break;

    case "bnplCreditors":
      statusEntryObject = Object.entries(BnplCreditorStatuses).find(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, statusEntry]) =>
          statusEntry.ptStatusCodes.includes(statusCode as BnplCreditorStatus)
      );
      break;

    case "bnplDebtors":
      statusEntryObject = Object.entries(BnplDebtorStatuses).find(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, statusEntry]) =>
          statusEntry.ptStatusCodes.includes(statusCode as BnplDebtorStatus)
      );
      break;
  }

  if (!statusEntryObject) {
    return;
  }

  const status = statusEntryObject[0];

  // eslint-disable-next-line consistent-return
  return status;
};

export const formatCurrency = (
  amount: number | undefined | null,
  currency: string | undefined | null = "HUF",
  locale = "hu-HU"
): string => {
  if (amount === undefined || amount === null) {
    return "-";
  }

  let formattingOptions: Intl.NumberFormatOptions = {
    style: "currency",
    currency: currency ?? "HUF",
    notation: "standard",
  };

  if (currency === "HUF") {
    formattingOptions = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      ...formattingOptions,
    };
  }

  const cleanedAmount = amount < 0.01 && amount > -0.01 ? 0 : amount;

  return new Intl.NumberFormat(locale, formattingOptions)
    .format(cleanedAmount)
    .replace("-", "- ");
};

export type FormattedData = (
  | Tables["vw_contract_with_orgs"]
  | Tables["vw_funding_with_orgs"]
  | Tables["vw_bnpl_contract_with_orgs"]
  | Tables["vw_bnpl_funding_with_orgs"]
  | Tables["vw_bnpl_creditor_with_org"]
  | Tables["vw_bnpl_debtor_with_org"]
) & {
  // eslint-disable-next-line camelcase
  creditor_org?: string;
  // eslint-disable-next-line camelcase
  debtor_org?: string;
};

export const formatContractRow = (
  data: Tables["vw_contract_with_orgs"]
): FormattedData => ({
  ...data,
  creditor_org: `${data.creditor_org_name} (${data.creditor_org_tax_number})`,
  debtor_org: `${data.debtor_org_name} (${data.debtor_org_tax_number})`,
});

export const formatFundingRow = (
  data: Tables["vw_funding_with_orgs"]
): FormattedData => ({
  ...data,
  creditor_org: `${data.creditor_org_name} (${data.creditor_org_tax_number})`,
  debtor_org: `${data.debtor_org_name} (${data.debtor_org_tax_number})`,
});

export const formatBnplContractRow = (
  data: Tables["vw_bnpl_contract_with_orgs"]
): FormattedData => ({
  ...data,
  creditor_org: `${data.creditor_short_name} (${data.creditor_tax_number})`,
  debtor_org: `${data.debtor_short_name} (${data.debtor_tax_number})`,
});

export const formatBnplFundingRow = (
  data: Tables["vw_bnpl_funding_with_orgs"]
): FormattedData => ({
  ...data,
  creditor_org: `${data.creditor_short_name} (${data.creditor_tax_number})`,
  debtor_org: `${data.debtor_short_name} (${data.debtor_tax_number})`,
});

export const formatBnplCreditorRow = (
  data: Tables["vw_bnpl_creditor_with_org"]
): FormattedData => ({
  ...data,
  creditor_org: `${data.creditor_short_name} (${data.creditor_tax_number})`,
});

export const formatBnplDebtorRow = (
  data: Tables["vw_bnpl_debtor_with_org"]
): FormattedData => ({
  ...data,
  debtor_org: `${data.debtor_short_name} (${data.debtor_tax_number})`,
});

export const getValue = (object: FormattedData, key: string): string =>
  object[key as keyof FormattedData]?.toString() ?? "";

export const formatValue = (object: FormattedData, key: string): string => {
  // eslint-disable-next-line no-prototype-builtins
  if (!object.hasOwnProperty(key)) {
    return "-unknown property-";
  }

  const value = getValue(object, key);

  if (["created_at", "updated_at"].includes(key) && value) {
    return formatTime(value);
  }

  if (["issue_date", "due_date"].includes(key) && value) {
    return formatDate(value);
  }

  if (["pd_score"].includes(key)) {
    return formatPd(Number(value));
  }

  return value;
};

export const openFile = async (file: string): Promise<void> => {
  const url = new URL(`storage/${file}`, BACKEND_BASE_URL);
  url.searchParams.set("token", sessionStorage.getItem("auth-token") ?? "");

  const a = document.createElement("a");

  a.style.display = "none";
  a.href = url.toString();
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(url.toString());

  await logInfo("Opened file", {
    file, // TODO category, id
  });
};

export const isTokenExpired = (token: string): boolean => {
  if (!token) {
    return true;
  }

  const payload = token.split(".")[1];

  const payloadData: {
    role: string;
    iat: number;
    exp: number;
  } = JSON.parse(Base64.decode(payload));

  return payloadData.exp * 1000 < Date.now();
};

export type EmptyObject = Record<string, never>;

export const getStatusTitle = (category: Category, status: string): string => {
  let StatusTitles;

  switch (category) {
    case "contracts":
    default:
      StatusTitles = ContractStatusTitles;
      break;

    case "fundings":
      StatusTitles = FundingStatusTitles;
      break;

    case "bnplContracts":
      StatusTitles = BnplContractStatusTitles;
      break;

    case "bnplFundings":
      StatusTitles = BnplFundingStatusTitles;
      break;

    case "bnplCreditors":
      StatusTitles = BnplCreditorStatusTitles;
      break;

    case "bnplDebtors":
      StatusTitles = BnplDebtorStatusTitles;
      break;
  }

  if (Object.keys(StatusTitles).includes(status)) {
    return StatusTitles[status as keyof typeof StatusTitles];
  }

  return status;
};

export const getToday = (): string => dayjs().format("YYYY-MM-DD");

interface IFile {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
}

export type File = IFile & Blob;
