import * as R from "ramda";

import store from "@/store";
import { TransactionSubjects } from "./consts/transactionSubjects";
import {
  GroupedTransactions,
  Tables,
  TransactionWithSourceBalance,
} from "./types";

// eslint-disable-next-line import/prefer-default-export
export const groupTransactions = (
  transactions: TransactionWithSourceBalance[]
): GroupedTransactions =>
  R.groupBy<TransactionWithSourceBalance>((transaction) =>
    [
      transaction.balance_id,
      transaction.contract_id || "",
      transaction.bnpl_contract_id || "",
      transaction.funding_id || "",
      transaction.bnpl_funding_id || "",
    ].join("-")
  )(transactions);

export const getTransactionSubject = (
  key: TransactionSubjects
): Tables["transaction_subjects"] | undefined =>
  store.state.transactionSubjects.find(({ id }) => id === key);

export const getUsageByTransactionSubjectProp = (
  prop: keyof Pick<
    Tables["transaction_subjects"],
    "does_affect_limit" | "does_affect_balance"
  >,
  transactions: TransactionWithSourceBalance[]
): number =>
  transactions.reduce((acc, cur) => {
    const subjectAffectsProp = getTransactionSubject(cur.subject)?.[prop];
    return subjectAffectsProp ? acc + cur.amount_gross : acc;
  }, 0);
