
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "Notification",
  props: {
    status: {
      type: String as PropType<
        "primary" | "info" | "success" | "warning" | "danger"
      >,
      required: true,
    },
    message: { type: String, required: true },
  },
});
