import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-903e339e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box" }
const _hoisted_2 = {
  key: 0,
  class: "field has-addons"
}
const _hoisted_3 = { class: "control button-container-1-2" }
const _hoisted_4 = { class: "control button-container-1-2" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currentStatusCode === 'BRF-10')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, [
            _createElementVNode("button", {
              class: "button is-danger is-fullwidth has-tooltip-arrow",
              "data-tooltip": "BRF-90",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.update('BRF-90')))
            }, " Elutasítás ")
          ]),
          _createElementVNode("p", _hoisted_4, [
            _createElementVNode("button", {
              class: "button is-success is-fullwidth has-tooltip-arrow",
              "data-tooltip": "BRF-11",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.update('BRF-11')))
            }, " Elfogadás ")
          ])
        ]))
      : (_openBlock(), _createElementBlock("p", _hoisted_5, "Nincsen(ek) lehetőség(ek)!"))
  ]))
}