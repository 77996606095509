import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a8ba7cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box" }
const _hoisted_2 = {
  key: 0,
  class: "field has-addons"
}
const _hoisted_3 = { class: "control button-container-1-2" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "control button-container-1-2" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currentStatusCode === 'BCF-10')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, [
            _createElementVNode("button", {
              class: "button is-danger is-fullwidth has-tooltip-arrow",
              "data-tooltip": "BCF-90",
              disabled: _ctx.disabled,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.update('BCF-90')))
            }, " Elutasítás ", 8, _hoisted_4)
          ]),
          _createElementVNode("p", _hoisted_5, [
            _createElementVNode("button", {
              class: "button is-success is-fullwidth has-tooltip-arrow",
              "data-tooltip": "BCF-11",
              disabled: _ctx.disabled,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.update('BCF-11')))
            }, " Elfogadás ", 8, _hoisted_6)
          ])
        ]))
      : (_openBlock(), _createElementBlock("p", _hoisted_7, "Nincsen(ek) lehetőség(ek)!"))
  ]))
}