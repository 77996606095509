
import { defineComponent, PropType } from "vue";

import CurrencyTag from "@/components/CurrencyTag.vue";
import { formatTime } from "@/utils";
import { deleteBalance } from "@/utils/httpsEndpoints";
import { Tables } from "@/utils/types";
import { logError } from "@/utils/systemLog";

export default defineComponent({
  name: "OrgBalances",
  components: { CurrencyTag },
  props: {
    balances: {
      type: Object as PropType<Tables["vw_balances"]>,
      required: true,
    },
  },
  emits: ["reloadData"], // TODO reimplement the data loading consistently with other parts of the app

  methods: {
    async archiveBalance(balanceId: string) {
      try {
        await deleteBalance(balanceId);

        this.$emit("reloadData");
      } catch (e) {
        await logError(
          "postgrest/deleting_balance_failed",
          `${(e as Error).name}: ${(e as Error).message}`,
          {
            id: balanceId,
            error: JSON.stringify(e),
          }
        );

        // eslint-disable-next-line no-alert
        alert("Error: Deleting balance"); // TODO use component-based error display
      }
    },
    formatTime,
  },
});
