
import { defineComponent, PropType } from "vue";
import { StatusCode } from "@pentech-web/pt-types/contract/status";

export default defineComponent({
  name: "ContractFlow",
  props: {
    currentStatusCode: {
      type: String as PropType<StatusCode>,
      required: true,
    },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    update(statusCode: StatusCode) {
      this.$emit("update", statusCode);
    },
  },
});
