
import { defineComponent, PropType } from "vue";

import CurrencyTag from "@/components/CurrencyTag.vue";
import Notification from "@/components/Notification.vue";
import store from "@/store";

import {
  addTransaction,
  getContract,
  getFunding,
  getSourceBalances,
} from "@/utils/httpsEndpoints";
import { Tables } from "@/utils/types";
import { logError } from "@/utils/systemLog";

export default defineComponent({
  name: "CreateBalanceModal",
  components: { CurrencyTag, Notification },
  props: {
    isOpen: { type: Boolean, required: true },
    balance: {
      type: Object as PropType<Tables["balances"]>,
      required: true,
    },
  },
  emits: ["close"],
  data() {
    return {
      isLoading: false,
      direction: "debit" as "credit" | "debit",
      amountNet: 0,
      vatRate: 0,
      subject: "",
      sourceBalance: "",
      sourceBalances: [] as Tables["balances"][],
      contractId: "",
      isContractIdValid: true,
      fundingId: "",
      isFundingIdValid: true,
      comment: "",
      isSource: false,
    };
  },
  computed: {
    amountGross() {
      return this.directedNetAmount * (1 + this.vatRate / 100);
    },
    directedNetAmount() {
      return Math.abs(this.amountNet) * (this.direction === "credit" ? 1 : -1);
    },
    subjectOptions() {
      return store.state.transactionSubjects.filter(
        (subject) =>
          (subject.is_credit && this.direction === "credit") ||
          (subject.is_credit === false && this.direction === "debit")
      );
    },
  },
  async mounted() {
    try {
      // TODO reimplement the data loading consistently with other parts of the app
      const loadedSourceBalances = (await getSourceBalances()).data;

      if (loadedSourceBalances) {
        this.sourceBalances = loadedSourceBalances.filter(
          (source) => source.currency === this.balance.currency
        );
      }
    } catch (e) {
      await logError(
        "postgrest/getting_source_balances_failed",
        `${(e as Error).name}: ${(e as Error).message}`,
        {
          error: JSON.stringify(e),
        }
      );

      // eslint-disable-next-line no-alert
      alert("Error: Getting source balances"); // TODO use component-based error display
    }
  },
  methods: {
    close() {
      this.direction = "debit";
      this.amountNet = 0;
      this.subject = "";
      this.sourceBalance = "";
      this.contractId = "";
      this.isContractIdValid = true;
      this.fundingId = "";
      this.isFundingIdValid = true;
      this.comment = "";
      this.isSource = false;
      this.$emit("close");
    },
    async submit() {
      try {
        this.isLoading = true;

        if (this.contractId) {
          this.isContractIdValid = Boolean(await getContract(this.contractId));

          if (!this.isContractIdValid) {
            throw new Error("Invalid contractId provided.");
          }
        }

        if (this.fundingId) {
          this.isFundingIdValid = Boolean(await getFunding(this.fundingId));

          if (!this.isFundingIdValid) {
            throw new Error("Invalid fundingId provided.");
          }
        }

        await addTransaction({
          balance_id: this.balance.id,
          currency: this.balance.currency,

          amount: this.directedNetAmount,
          vat_rate: this.vatRate / 100,
          subject: this.subject,
          comment: this.comment,
          contract_id: this.contractId || null,
          funding_id: this.fundingId || null,
          bnpl_contract_id: null,
          bnpl_funding_id: null,
          source_balance_id: this.sourceBalance || null,
        });

        this.close();
      } catch (e) {
        await logError(
          "postgrest/adding_transaction_failed",
          `${(e as Error).name}: ${(e as Error).message}`,
          {
            balanceId: this.balance.id,
            error: JSON.stringify(e),
          }
        );

        // eslint-disable-next-line no-alert
        alert("Error: Adding transaction"); // TODO use component-based error display
      } finally {
        this.isLoading = false;
      }
    },
  },
});
