
import { defineComponent, PropType } from "vue";

import store from "@/store";
import Notification from "@/components/Notification.vue";
import { formatDate, formatPd } from "@/utils";
import { Tables } from "@/utils/types";
import { getBalances } from "@/utils/httpsEndpoints";

import { logError } from "@/utils/systemLog";
import OrgBalances from "../../components/orgs/OrgBalances.vue";
import CreateBalanceModal from "../../components/orgs/CreateBalanceModal.vue";

export default defineComponent({
  name: "Org",
  components: { CreateBalanceModal, Notification, OrgBalances },
  props: {
    orgType: {
      type: String as PropType<"orgs" | "bnplCreditors" | "bnplDebtors">,
      default: "orgs",
    },
  },
  data() {
    return {
      balances: [] as Tables["vw_balances"][],
      orgId: this.$route.params.orgId as string,
      org: {} as
        | Tables["orgs"]
        | Tables["vw_bnpl_creditor_with_org"]
        | Tables["vw_bnpl_debtor_with_org"],
      modalIsOpen: false,
    };
  },
  async mounted() {
    await store.loadData("orgs");

    switch (this.orgType) {
      case "orgs":
      default:
        const org = store.state.orgs.find((o) => o.id === this.orgId);

        if (org) {
          this.org = org;
        }

        break;
      case "bnplCreditors":
        await store.loadData("bnplCreditors");

        const creditor = store.state.bnplCreditors.find(
          (o) => o.id === this.orgId
        );
        const creditorOrg = store.state.orgs.find(
          (o) => o.id === creditor?.creditor_org_id
        );

        if (creditorOrg) {
          this.org = creditorOrg;
        }

        break;
      case "bnplDebtors":
        await store.loadData("bnplDebtors");

        const debtor = store.state.bnplDebtors.find((o) => o.id === this.orgId);
        const debtorOrg = store.state.orgs.find(
          (o) => o.id === debtor?.debtor_id
        );

        if (debtorOrg) {
          this.org = debtorOrg;
        }

        break;
    }

    this.loadData();
  },
  methods: {
    formatDate,
    formatPd,
    // TODO reimplement the data loading consistently with other parts of the app
    async loadData() {
      try {
        if (!this) {
          return;
        }

        const loadedBalances = (await getBalances(this?.orgId, true)).data;

        if (loadedBalances) {
          this.balances = loadedBalances;
        }
      } catch (e) {
        await logError(
          "postgrest/getting_balances_failed",
          `${(e as Error).name}: ${(e as Error).message}`,
          {
            category: this.orgType,
            orgId: this.orgId,
            error: JSON.stringify(e),
          }
        );

        // eslint-disable-next-line no-alert
        alert("Error: Getting balances"); // TODO use component-based error display
      }
    },
    onModalClose() {
      this.modalIsOpen = false;
      this.loadData();
    },
  },
});
