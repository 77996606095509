
import { defineComponent, PropType } from "vue";
import { formatCurrency } from "@/utils";

export type TagColor =
  | "black"
  | "dark"
  | "light"
  | "white"
  | "primary"
  | "link"
  | "info"
  | "success"
  | "warning"
  | "danger";

export default defineComponent({
  props: {
    amount: { type: Number, required: true },
    currency: { type: String, required: true },
    color: {
      type: String as PropType<TagColor>,
      default: "dark",
    },
    label: { type: String, required: false, default: undefined },
    isLight: { type: Boolean, default: false },
  },
  computed: {
    displayedAmount(): string {
      return formatCurrency(this.amount, this.currency);
    },
    tagClass(): string[] {
      return [`is-${this.color}`, this.isLight ? "is-light" : ""];
    },
  },
});
