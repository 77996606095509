<template>
  <section class="section">
    <div class="container">
      <section class="hero is-danger">
        <div class="hero-body">
          <p class="title">Hoppá</p>
          <p class="subtitle">
            Eltévedtél, térj vissza a
            <a href="/"><span class="link">kezdőlapra</span></a
            >!
          </p>
        </div>
      </section>
    </div>
  </section>
</template>

<style scoped lang="scss">
.link {
  text-decoration: underline;
}
</style>
