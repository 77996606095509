
import { defineComponent, PropType } from "vue";
import * as Sentry from "@sentry/vue";
import store from "@/store";
import Details from "@/components/Row/Details.vue";
import Flow from "@/components/Row/Flow.vue";
import Documents from "@/components/Row/Documents.vue";
import { Category, StatusCode, openFile, getToday } from "@/utils";
import { getDocumentUrls, postgrest } from "@/utils/httpsEndpoints";
import { Tables } from "@/utils/types";
import Notification from "@/components/Notification.vue";
import DataLoader from "@/components/DataLoader.vue";
import UploadDocument from "@/components/Row/UploadDocument.vue";
import { logDebug, logError, logInfo } from "@/utils/systemLog";

type CheckboxesNotification =
  | { status: "success" | "danger" | "warning"; message: string }
  | Record<string, never>;

type ButtonsNotification =
  | { status: "danger"; message: string }
  | Record<string, never>;

type UploadDocumentNotification =
  | { status: "success" | "danger"; message: string }
  | Record<string, never>;

const needsDualApproval = (
  category: Category,
  data:
    | Tables["vw_contract_with_orgs"]
    | Tables["vw_funding_with_orgs"]
    | Tables["vw_bnpl_contract_with_orgs"]
    | Tables["vw_bnpl_funding_with_orgs"]
    | Tables["vw_bnpl_creditor_with_org"]
    | Tables["vw_bnpl_debtor_with_org"],
  newStatusCode: StatusCode
) => {
  const dualApprovalForContracts =
    category === "contracts" &&
    data.status_code === "CF-11" &&
    newStatusCode === "CF-12";
  const dualApprovalForBnplCreditors =
    category === "bnplCreditors" &&
    data.status_code === "BCF-10" &&
    newStatusCode === "BCF-11";

  return dualApprovalForContracts || dualApprovalForBnplCreditors;
};

export default defineComponent({
  name: "Row",
  components: {
    Details,
    Flow,
    Documents,
    UploadDocument,
    Notification,
    DataLoader,
  },
  props: {
    activeCategory: {
      type: String as PropType<Category>,
      default: "contracts",
    },
    selectedRow: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // TODO rename variables according to component renames
      checkboxesNotification: {},
      buttonsNotification: {},
      uploadDocumentNofication: {},
      checkboxesDisabled: false,
    } as {
      checkboxesNotification: CheckboxesNotification;
      buttonsNotification: ButtonsNotification;
      uploadDocumentNofication: UploadDocumentNotification;
      checkboxesDisabled: boolean;
    };
  },
  computed: {
    data() {
      switch (this.activeCategory) {
        case "contracts":
        default:
          return store.state.contracts.find(
            (row) => row.id === this.selectedRow
          );
        case "fundings":
          return store.state.fundings.find(
            (row) => row.id === this.selectedRow
          );
        case "bnplContracts":
          return store.state.bnplContracts.find(
            (row) => row.id === this.selectedRow
          );
        case "bnplFundings":
          return store.state.bnplFundings.find(
            (row) => row.id === this.selectedRow
          );
        case "bnplCreditors":
          return store.state.bnplCreditors.find(
            (row) => row.id === this.selectedRow
          );
        case "bnplDebtors":
          return store.state.bnplDebtors.find(
            (row) => row.id === this.selectedRow
          );
      }
    },
    needs_insurance() {
      return this.activeCategory === "contracts"
        ? (this.data as Tables["vw_contract_with_orgs"])?.needs_insurance
        : false;
    },
  },
  methods: {
    async update({
      statusCode,
      dateOfProcessing,
    }: {
      statusCode: StatusCode;
      dateOfProcessing: string;
    }) {
      if (!this.data || !this.data.id) {
        return;
      }

      if (!dateOfProcessing || dateOfProcessing.length === 0) {
        // eslint-disable-next-line no-param-reassign
        dateOfProcessing = getToday();
        await logDebug(
          "No dateOfProcessing provided. Using today as default.",
          {
            statusCode,
            dateOfProcessing,
          }
        );
      }

      // TODO move logic to component
      if (needsDualApproval(this.activeCategory, this.data, statusCode)) {
        await logDebug("Set status needs dual approval", {
          category: this.activeCategory,
          id: this.selectedRow,
          statusCode,
          dateOfProcessing,
        });

        let approvals;

        switch (this.activeCategory) {
          case "contracts":
          default:
            approvals = store.state.contractMeta.filter(
              (row) =>
                row.contract_id === this.selectedRow &&
                row.subject === "APPROVAL"
            );
            break;
          case "bnplCreditors":
            approvals = store.state.bnplCreditorMeta.filter(
              (row) =>
                row.bnpl_creditor_id === this.selectedRow &&
                row.subject === "APPROVAL"
            );
            break;
        }

        if (sessionStorage.getItem("user-can-approve") !== "true") {
          this.checkboxesNotification = {
            status: "danger",
            message:
              "Sikertelen jóváhagyás (a felhasználónak nincs jóváhagyási joga)!",
          };

          await logError(
            "set_status/user_cant_approve",
            `User can't approve this.`,
            {
              category: this.activeCategory,
              id: this.selectedRow,
              event: "APPROVE",
              statusCode,
              dateOfProcessing,
            }
          );

          return;
        }

        if (!approvals.length) {
          switch (this.activeCategory) {
            case "contracts":
            default:
              await postgrest
                .auth(sessionStorage.getItem("auth-token") || "")
                .from("contract_meta")
                .insert({
                  subject: "APPROVAL",
                  contract_id: this.selectedRow,
                  user_id: sessionStorage.getItem("user-id"),
                });
              break;
            case "bnplCreditors":
              await postgrest
                .auth(sessionStorage.getItem("auth-token") || "")
                .from("bnpl_creditor_meta")
                .insert({
                  subject: "APPROVAL",
                  bnpl_creditor_id: this.selectedRow,
                  user_id: sessionStorage.getItem("user-id"),
                });
              break;
          }

          this.checkboxesDisabled = true;

          this.checkboxesNotification = {
            status: "warning",
            message:
              "Sikeres jóváhagyás! A státuszváltás a második jóváhagyás után történik meg.",
          };

          await logInfo("Set status", {
            category: this.activeCategory,
            id: this.selectedRow,
            event: "APPROVE",
            statusCode,
            dateOfProcessing,
          });

          return;
        }

        if (approvals[0].user_id === sessionStorage.getItem("user-id")) {
          this.checkboxesNotification = {
            status: "danger",
            message: "Sikertelen jóváhagyás (ez a felhasználó már jóváhagyta)!",
          };

          await logError(
            "set_status/user_already_approved",
            `User already approved this.`,
            {
              category: this.activeCategory,
              id: this.selectedRow,
              event: "APPROVE",
              statusCode,
              dateOfProcessing,
            }
          );

          return;
        }
      }

      try {
        await store.setStatus(
          this.activeCategory,
          this.data.id,
          statusCode,
          dateOfProcessing
        );

        this.checkboxesNotification = {
          status: "success",
          message: "Sikeres státusz módosítás!",
        };

        await logInfo("Set status", {
          category: this.activeCategory,
          id: this.selectedRow,
          statusCode,
          dateOfProcessing,
        });
      } catch (e) {
        this.checkboxesNotification = {
          status: "danger",
          message: "Sikertelen státusz módosítás!",
        };

        await logError(
          "set_status_failure",
          `${(e as Error).name}: ${(e as Error).message}`,
          {
            category: this.activeCategory,
            id: this.selectedRow,
            statusCode,
            dateOfProcessing,
            error: JSON.stringify(e),
          }
        );
      }
    },
    async open(categoryFilter: string) {
      const errorNotification: ButtonsNotification = {
        status: "danger",
        message: "Sikertelen dokumentum megnyitás!",
      };

      this.buttonsNotification = {};

      // TODO move logic to component
      try {
        const { data: documents } = await getDocumentUrls(
          this.activeCategory,
          this.selectedRow
        );

        if (!documents) {
          this.buttonsNotification = errorNotification;

          await logError(
            "file_open/not_found",
            "Empty response from getDocumentUrls.",
            {
              category: this.activeCategory,
              id: this.selectedRow,
              categoryFilter,
            }
          );

          return;
        }

        if (categoryFilter) {
          const filteredDocument = documents.filter(
            (document) => document.category === categoryFilter
          );

          if (!filteredDocument.length) {
            this.buttonsNotification = errorNotification;

            await logError(
              "file_open/not_found",
              "Empty response from documents.filter.",
              {
                category: this.activeCategory,
                id: this.selectedRow,
                categoryFilter,
              }
            );

            return;
          }

          filteredDocument.map((document) => openFile(document.file_path));
        } else {
          documents.map((document) => openFile(document.file_path));
        }
      } catch (e) {
        Sentry.captureException(e);
        this.buttonsNotification = errorNotification;

        await logError(
          "file_open_failure",
          `${(e as Error).name}: ${(e as Error).message}`,
          {
            category: this.activeCategory,
            id: this.selectedRow,
            categoryFilter,
            error: JSON.stringify(e),
          }
        );
      }
    },
    upload(isError: boolean) {
      const errorNotification: UploadDocumentNotification = {
        status: "danger",
        message: "Sikertelen dokumentum feltöltés!",
      };

      const successNotification: UploadDocumentNotification = {
        status: "success",
        message: "Sikeres dokumentum feltöltés!",
      };

      this.uploadDocumentNofication = isError
        ? errorNotification
        : successNotification;
    },
  },
});
