import { createApp } from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faChevronCircleDown,
  faChevronCircleUp,
  faCircle,
  faEye,
  faInfoCircle,
  faMinus,
  faPercent,
  faPlus,
  faSpinner,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import "@creativebulma/bulma-tooltip/dist/bulma-tooltip.css";

import App from "./App.vue";
import router from "./router";

import "./idleTimer";

library.add(
  faArrowLeft,
  faChevronCircleDown,
  faChevronCircleUp,
  faCircle,
  faEye,
  faInfoCircle,
  faMinus,
  faPercent,
  faPlus,
  faSpinner,
  faTimesCircle
);

const app = createApp(App);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_FRONTEND_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "localhost",
        "sfondo.pentech.hu",
        "sfondo.staging.petnech.hu",
        /^\//,
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: true,
});

const customApp = app.component("FontAwesomeIcon", FontAwesomeIcon);

customApp.use(router).mount("#app");
