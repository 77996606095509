import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-319e54e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field has-addons" }
const _hoisted_2 = { class: "control input-container" }
const _hoisted_3 = {
  key: 0,
  class: "control"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
        class: "input",
        type: "text",
        placeholder: "Keresés",
        onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)))
      }, null, 544), [
        [_vModelText, _ctx.query]
      ])
    ]),
    (_ctx.query.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("a", {
            class: "button",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args)))
          }, [
            _createVNode(_component_FontAwesomeIcon, { icon: ['fas', 'times-circle'] })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}