
import { defineComponent, PropType } from "vue";
import { Category } from "@/utils";
import store from "@/store";

export default defineComponent({
  name: "DataLoader",
  props: {
    activeCategory: {
      type: String as PropType<Category>,
      default: "contracts",
    },
  },
  data() {
    return {
      dataLoaded: true,
    };
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.loadData();
      },
      { immediate: true }
    );
  },
  methods: {
    async loadData() {
      switch (this.$route.params.activeCategory) {
        case "contracts":
        default:
          if (!store.state.contractsLoaded) {
            this.dataLoaded = false;

            await store.loadData("contracts");
            this.dataLoaded = true;
          }
          break;

        case "fundings":
          if (!store.state.fundingsLoaded) {
            this.dataLoaded = false;

            await store.loadData("fundings");
            this.dataLoaded = true;
          }
          break;

        case "orgs":
          if (!store.state.orgsLoaded) {
            this.dataLoaded = false;

            await store.loadData("orgs");
            this.dataLoaded = true;
          }
          break;

        case "transactionSubjects":
          if (!store.state.transactionSubjectsLoaded) {
            this.dataLoaded = false;

            await store.loadData("transactionSubjects");
            this.dataLoaded = true;
          }
          break;

        case "bnplContracts":
          if (!store.state.bnplContractsLoaded) {
            this.dataLoaded = false;

            await store.loadData("bnplContracts");
            this.dataLoaded = true;
          }
          break;

        case "bnplFundings":
          if (!store.state.bnplFundingsLoaded) {
            this.dataLoaded = false;

            await store.loadData("bnplFundings");
            this.dataLoaded = true;
          }
          break;

        case "bnplCreditors":
          if (!store.state.bnplCreditorsLoaded) {
            this.dataLoaded = false;

            await store.loadData("bnplCreditors");
            this.dataLoaded = true;
          }
          break;

        case "bnplDebtors":
          if (!store.state.bnplDebtorsLoaded) {
            this.dataLoaded = false;

            await store.loadData("bnplDebtors");
            this.dataLoaded = true;
          }
          break;
      }
    },
  },
});
