import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Login from "../views/Login.vue";
import Maintenance from "../views/Maintenance.vue";
import Error404 from "../views/Error404.vue";
import Row from "../views/Row.vue";
import Table from "../views/Table.vue";
import Org from "../views/orgs/Org.vue";
import Balance from "../views/orgs/Balance.vue";
import Sidebar from "../components/Sidebar.vue";
import { isTokenExpired } from "@/utils";
import { logError, logInfo } from "@/utils/systemLog";
import { JsonObject } from "@/utils/types";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Index",
    components: { default: Table, Sidebar },
  },
  {
    path: "/:activeCategory/:activeStatus",
    name: "Table",
    components: { default: Table, Sidebar },
    props: true,
  },
  {
    path: "/:activeCategory/:selectedRow/view",
    name: "Row",
    components: { default: Row, Sidebar },
    props: true,
  },
  {
    path: "/orgs/:orgType/:orgId/view",
    name: "Org",
    components: { default: Org, Sidebar },
    props: true,
  },
  {
    path: "/balances/:balanceId/view",
    name: "Balance",
    components: { default: Balance, Sidebar },
    props: true,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
  },
  {
    path: "/:pathMatch(.*)*",
    component: Error404,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _, next) => {
  const token = sessionStorage.getItem("auth-token");
  const isAuthenticated = !isTokenExpired(token || "");

  if (to.name !== "Login" && !isAuthenticated) {
    sessionStorage.setItem("auth-token", "");

    next({ name: "Login" });
  } else if (to.name === "Login" && isAuthenticated) {
    next({ name: "Index" });
  } else {
    next();
  }
});

router.afterEach(async (to, from, failure) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { matched: _, ...importantFromOriginalTo } = to;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { matched: __, ...importantFromOriginalFrom } = from;

  const toAndFrom = {
    to: importantFromOriginalTo,
    from: importantFromOriginalFrom,
  } as JsonObject;

  if (failure) {
    await logError("navigation_failure", "", {
      ...toAndFrom,
      error: JSON.stringify(failure),
    });
  } else {
    await logInfo("Navigated", toAndFrom);
  }
});

export default router;
