type methodType = "PUT" | "POST" | "GET" | "PATCH";

const sendRequest =
  (apiBase: string) =>
  async ({
    endpoint,
    payload,
    method,
    extraHeaders = {},
    jsonHeaders = [],
    query,
  }: {
    endpoint: string;
    payload: unknown;
    method: methodType;
    extraHeaders: { [key: string]: string };
    jsonHeaders?: string[];
    query?: { [key: string]: string | number };
  }): Promise<unknown> => {
    const headers = new Headers();

    if (["POST", "PUT"].includes(method)) {
      headers.set("content-type", "application/json;charset=UTF-8");
    }

    headers.set("Accept", "application/json");

    Object.entries(extraHeaders).forEach(([key, value]) => {
      headers.set(key, value);
    });

    headers.set(
      "Authorization",
      `Bearer ${sessionStorage.getItem("auth-token")}`
    );

    const queryString = query
      ? `?${new URLSearchParams(query as Record<string, string>).toString()}`
      : "";

    const request = await fetch(`${apiBase}/${endpoint}${queryString}`, {
      method,
      headers,
      body: headers.get("content-type")?.startsWith("application/json")
        ? JSON.stringify(payload)
        : (payload as Blob),
    });

    const contentType = request.headers.get("content-type");

    let responsePayload;
    if (
      contentType?.startsWith("application/json") ||
      jsonHeaders.some((header) => contentType?.startsWith(header))
    ) {
      responsePayload = await request.json();
    }
    // TODO: excel, xml, etc.
    else if (contentType?.startsWith("application/zip")) {
      responsePayload = await request.blob();
    } else {
      responsePayload = await request.text();
    }

    if (request.status >= 400) {
      throw new Error(responsePayload.message);
    }

    return responsePayload;
  };

export const BACKEND_BASE_URL =
  process.env.VUE_APP_BACKEND_BASE_URL || `https://${window.location.host}/api`;

export const httpsApi = async ({
  endpoint,
  payload,
  method = "POST",
  extraHeaders = {},
}: {
  endpoint: string;
  payload?: unknown;
  auth?: boolean;
  method?: methodType;
  extraHeaders?: Record<string, string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<any> => {
  return sendRequest(BACKEND_BASE_URL)({
    endpoint,
    payload,
    method,
    extraHeaders,
  });
};

export default httpsApi;
