
import { defineComponent, PropType } from "vue";
import CurrencyTag from "@/components/CurrencyTag.vue";
import { Category, formatValue, FormattedData, getValue } from "../../utils";
import { ContractDataGridColumns } from "@/utils/consts/contracts";
import { FundingDataGridColumns } from "@/utils/consts/fundings";
import { ContractDataGridColumns as BnplContractDataGridColumns } from "@/utils/consts/bnpl/contracts";
import { FundingDataGridColumns as BnplFundingDataGridColumns } from "@/utils/consts/bnpl/fundings";
import { CreditorDataGridColumns } from "@/utils/consts/bnpl/creditors";
import { DebtorDataGridColumns } from "@/utils/consts/bnpl/debtors";
import { OrgsDataGridColumns } from "@/utils/consts/orgs";

export default defineComponent({
  name: "DataGrid",
  components: { CurrencyTag },
  props: {
    data: {
      type: Object as PropType<FormattedData[]>,
      required: true,
    },
    activeCategory: {
      type: String as PropType<Category | "orgs">,
      required: true,
    },
  },
  computed: {
    columns() {
      switch (this.activeCategory) {
        case "contracts":
          return ContractDataGridColumns;
        case "fundings":
          return FundingDataGridColumns;
        case "bnplContracts":
          return BnplContractDataGridColumns;
        case "bnplFundings":
          return BnplFundingDataGridColumns;
        case "bnplCreditors":
          return CreditorDataGridColumns;
        case "bnplDebtors":
          return DebtorDataGridColumns;
        case "orgs":
          return OrgsDataGridColumns;
        default:
          return {};
      }
    },
  },
  methods: {
    getValue,
    formatValue,
  },
});
