import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ddc0d68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box" }
const _hoisted_2 = {
  key: 0,
  class: "field has-addons"
}
const _hoisted_3 = { class: "control button-container-1-2" }
const _hoisted_4 = { class: "control button-container-1-2" }
const _hoisted_5 = {
  key: 1,
  class: "field has-addons"
}
const _hoisted_6 = { class: "control button-container-1-2" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "control button-container-1-2" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currentStatusCode === 'CF-10')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, [
            _createElementVNode("button", {
              class: "button is-danger is-fullwidth has-tooltip-arrow",
              "data-tooltip": "CF-90",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.update('CF-90')))
            }, " Elutasítás ")
          ]),
          _createElementVNode("p", _hoisted_4, [
            _createElementVNode("button", {
              class: "button is-success is-fullwidth has-tooltip-arrow",
              "data-tooltip": "CF-11",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.update('CF-11')))
            }, " KHR sikeres ")
          ])
        ]))
      : (_ctx.currentStatusCode === 'CF-11')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, [
              _createElementVNode("button", {
                class: "button is-danger is-fullwidth has-tooltip-arrow",
                "data-tooltip": "CF-91",
                disabled: _ctx.disabled,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.update('CF-91')))
              }, " Elutasítás ", 8, _hoisted_7)
            ]),
            _createElementVNode("p", _hoisted_8, [
              _createElementVNode("button", {
                class: "button is-success is-fullwidth has-tooltip-arrow",
                "data-tooltip": "CF-12",
                disabled: _ctx.disabled,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.update('CF-12')))
              }, " Elfogadás ", 8, _hoisted_9)
            ])
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_10, "Nincsen(ek) lehetőség(ek)!"))
  ]))
}