/* eslint-disable no-case-declarations */
/* eslint-disable no-console */
import * as R from "ramda";
import { reactive } from "vue";

import { setStatus, getEntity } from "@/utils/httpsEndpoints";
import { Category, StatusCode } from "@/utils";

import { Tables } from "../utils/types";
import { logDebug, logError } from "@/utils/systemLog";

export default {
  state: reactive({
    contracts: [],
    fundings: [],

    orgs: [],
    transactionSubjects: [],

    bnplContracts: [],
    bnplFundings: [],

    bnplCreditors: [],
    bnplDebtors: [],

    contractMeta: [],
    bnplCreditorMeta: [],

    contractsLoaded: false,
    fundingsLoaded: false,

    orgsLoaded: false,
    transactionSubjectsLoaded: false,

    bnplContractsLoaded: false,
    bnplFundingsLoaded: false,

    bnplCreditorsLoaded: false,
    bnplDebtorsLoaded: false,
  } as {
    contracts: Tables["vw_contract_with_orgs"][];
    fundings: Tables["vw_funding_with_orgs"][];

    orgs: Tables["orgs"][];
    transactionSubjects: Tables["transaction_subjects"][];

    bnplContracts: Tables["vw_bnpl_contract_with_orgs"][];
    bnplFundings: Tables["vw_bnpl_funding_with_orgs"][];

    bnplCreditors: Tables["vw_bnpl_creditor_with_org"][];
    bnplDebtors: Tables["vw_bnpl_debtor_with_org"][];

    contractMeta: Tables["contract_meta"][];
    bnplCreditorMeta: Tables["bnpl_creditor_meta"][];

    contractsLoaded: boolean;
    fundingsLoaded: boolean;

    orgsLoaded: boolean;
    transactionSubjectsLoaded: boolean;

    bnplContractsLoaded: boolean;
    bnplFundingsLoaded: boolean;

    bnplCreditorsLoaded: boolean;
    bnplDebtorsLoaded: boolean;
  }),

  async setStatus(
    category: Category,
    id: string,
    statusCode: StatusCode,
    dateOfProcessing: string
  ): Promise<void> {
    console.debug({
      category,
      id,
      statusCode,
      dateOfProcessing,
    });

    switch (category) {
      case "contracts":
      default:
        this.state.contracts = await Promise.all(
          this.state.contracts.map(async (contract) => {
            if (contract.id === id) {
              const { status } = await setStatus(
                category,
                id,
                statusCode,
                dateOfProcessing
              );

              if (status !== 200) {
                throw new Error("Status update failed!");
              }

              return { ...contract, status_code: statusCode };
            }

            return contract;
          })
        );

        if (statusCode === "CF-12") {
          await logDebug("Set status calls another setStatus automatically", {
            category,
            id,
            statusCode: "CP-13",
          });

          await this.setStatus(category, id, "CP-13", dateOfProcessing);
        }
        break;

      case "fundings":
        this.state.fundings = await Promise.all(
          this.state.fundings.map(async (funding) => {
            if (funding.id === id) {
              const { status } = await setStatus(
                category,
                id,
                statusCode,
                dateOfProcessing
              );

              if (status !== 200) {
                throw new Error("Status update failed!");
              }

              return { ...funding, status_code: statusCode };
            }

            return funding;
          })
        );
        break;

      case "bnplContracts":
        this.state.bnplContracts = await Promise.all(
          this.state.bnplContracts.map(async (bnplContract) => {
            if (bnplContract.id === id) {
              const { status } = await setStatus(
                category,
                id,
                statusCode,
                dateOfProcessing
              );

              if (status !== 200) {
                throw new Error("Status update failed!");
              }

              return { ...bnplContract, status_code: statusCode };
            }

            return bnplContract;
          })
        );
        break;

      case "bnplFundings":
        this.state.bnplFundings = await Promise.all(
          this.state.bnplFundings.map(async (bnplFunding) => {
            if (bnplFunding.id === id) {
              const { status } = await setStatus(
                category,
                id,
                statusCode,
                dateOfProcessing
              );

              if (status !== 200) {
                throw new Error("Status update failed!");
              }

              return { ...bnplFunding, status_code: statusCode };
            }

            return bnplFunding;
          })
        );
        break;

      case "bnplCreditors":
        this.state.bnplCreditors = await Promise.all(
          this.state.bnplCreditors.map(async (bnplCreditor) => {
            if (bnplCreditor.id === id) {
              const { status } = await setStatus(
                category,
                id,
                statusCode,
                dateOfProcessing
              );

              if (status !== 200) {
                throw new Error("Status update failed!");
              }

              return { ...bnplCreditor, status_code: statusCode };
            }

            return bnplCreditor;
          })
        );
        break;

      case "bnplDebtors":
        this.state.bnplDebtors = await Promise.all(
          this.state.bnplDebtors.map(async (bnplDebtor) => {
            if (bnplDebtor.id === id) {
              const { status } = await setStatus(
                category,
                id,
                statusCode,
                dateOfProcessing
              );

              if (status !== 200) {
                throw new Error("Status update failed!");
              }

              return { ...bnplDebtor, status_code: statusCode };
            }

            return bnplDebtor;
          })
        );
        break;
    }
  },

  async loadData(
    entity:
      | "contracts"
      | "fundings"
      | "orgs"
      | "transactionSubjects"
      | "bnplContracts"
      | "bnplFundings"
      | "bnplCreditors"
      | "bnplDebtors"
  ): Promise<void> {
    try {
      switch (entity) {
        case "contracts":
        default:
          await logDebug("Loading data", {
            entities: ["vw_contract_with_orgs", "contract_meta"],
          });

          const { data: contracts } = await getEntity("vw_contract_with_orgs");
          const { data: contractMeta } = await getEntity("contract_meta");

          if (!contracts || !contractMeta) {
            await logError("postgrest/loading_data_failed", "", {
              entities: ["vw_contract_with_orgs", "contract_meta"],
            });

            throw Error(`Unable to load contracts/contractMeta`);
          }

          this.state.contracts = [...contracts];
          this.state.contractMeta = [...contractMeta];
          this.state.contractsLoaded = true;
          break;

        case "fundings":
          await logDebug("Loading data", {
            entities: ["vw_funding_with_orgs"],
          });

          const { data: fundings } = await getEntity("vw_funding_with_orgs");

          if (!fundings) {
            await logError("postgrest/loading_data_failed", "", {
              entities: ["vw_funding_with_orgs"],
            });

            throw Error(`Unable to load fundings`);
          }

          this.state.fundings = [...fundings];
          this.state.fundingsLoaded = true;
          break;

        case "orgs":
          await logDebug("Loading data", {
            entities: ["orgs"],
          });

          const { data: orgs } = await getEntity("orgs");

          if (!orgs) {
            await logError("postgrest/loading_data_failed", "", {
              entities: ["orgs"],
            });

            throw Error(`Unable to load orgs`);
          }

          this.state.orgs = [...orgs];
          this.state.orgsLoaded = true;
          break;

        case "transactionSubjects":
          await logDebug("Loading data", {
            entities: ["transaction_subjects"],
          });

          const { data: transactionSubjects } = await getEntity(
            "transaction_subjects"
          );

          if (!transactionSubjects) {
            await logError("postgrest/loading_data_failed", "", {
              entities: ["transaction_subjects"],
            });

            throw Error(`Unable to load transactionSubjects`);
          }

          this.state.transactionSubjects = [...transactionSubjects];
          this.state.transactionSubjectsLoaded = true;
          break;

        case "bnplContracts":
          await logDebug("Loading data", {
            entities: ["vw_bnpl_contract_with_orgs"],
          });

          const { data: bnplContracts } = await getEntity(
            "vw_bnpl_contract_with_orgs"
          );

          if (!bnplContracts) {
            await logError("postgrest/loading_data_failed", "", {
              entities: ["vw_bnpl_contract_with_orgs"],
            });

            throw Error(`Unable to load bnplContracts`);
          }

          this.state.bnplContracts = [...bnplContracts];
          this.state.bnplContractsLoaded = true;
          break;

        case "bnplFundings":
          await logDebug("Loading data", {
            entities: ["vw_bnpl_funding_with_orgs"],
          });

          const { data: bnplFundings } = await getEntity(
            "vw_bnpl_funding_with_orgs"
          );

          if (!bnplFundings) {
            await logError("postgrest/loading_data_failed", "", {
              entities: ["vw_bnpl_funding_with_orgs"],
            });

            throw Error(`Unable to load bnplFundings`);
          }

          this.state.bnplFundings = [...bnplFundings];
          this.state.bnplFundingsLoaded = true;
          break;

        case "bnplCreditors":
          await logDebug("Loading data", {
            entities: ["vw_bnpl_creditor_with_org", "bnpl_creditor_meta"],
          });

          const { data: bnplCreditors } = await getEntity(
            "vw_bnpl_creditor_with_org"
          );
          const { data: bnplCreditorMeta } = await getEntity(
            "bnpl_creditor_meta"
          );

          if (!bnplCreditors || !bnplCreditorMeta) {
            await logError("postgrest/loading_data_failed", "", {
              entities: ["vw_bnpl_creditor_with_org", "bnpl_creditor_meta"],
            });

            throw Error(`Unable to load bnplCreditors/bnplCreditorMeta`);
          }

          this.state.bnplCreditors = [...bnplCreditors];
          this.state.bnplCreditorMeta = [...bnplCreditorMeta];
          this.state.bnplCreditorsLoaded = true;
          break;

        case "bnplDebtors":
          await logDebug("Loading data", {
            entities: ["vw_bnpl_debtor_with_org"],
          });

          const { data: bnplDebtors } = await getEntity(
            "vw_bnpl_debtor_with_org"
          );

          if (!bnplDebtors) {
            await logError("postgrest/loading_data_failed", "", {
              entities: ["vw_bnpl_debtor_with_org"],
            });

            throw Error(`Unable to load bnplDebtors`);
          }

          this.state.bnplDebtors = [...bnplDebtors];
          this.state.bnplDebtorsLoaded = true;
          break;
      }
    } catch (err) {
      console.error("Could not load some mandatory resources");
      throw err;
    }
  },

  getPropOfFunding<T extends Tables["vw_funding_with_orgs"], K extends keyof T>(
    prop: K,
    id: string
  ): T[K] | undefined {
    return R.prop(prop, R.find(R.propEq("id", id), this.state.fundings));
  },

  getPropOfContract<
    T extends Tables["vw_contract_with_orgs"],
    K extends keyof T
  >(prop: K, id: string): T[K] | undefined {
    return R.prop(prop, R.find(R.propEq("id", id), this.state.contracts));
  },

  getPropOfBnplFunding<
    T extends Tables["vw_bnpl_funding_with_orgs"],
    K extends keyof T
  >(prop: K, id: string): T[K] | undefined {
    return R.prop(prop, R.find(R.propEq("id", id), this.state.bnplFundings));
  },

  getPropOfBnplContract<
    T extends Tables["vw_bnpl_contract_with_orgs"],
    K extends keyof T
  >(prop: K, id: string): T[K] | undefined {
    return R.prop(prop, R.find(R.propEq("id", id), this.state.bnplContracts));
  },
};
