import { CreditorStatusCode as Status } from "@pentech-web/pt-types/bnpl/statusCodes";

/*
  'BCC-10': created
  'BCP-10': upsert_to_factor
  'BCP-90': auto_ko
  'BCP-91': limit_rejected

  'BCF-10': received_by_factor
  'BCC-11': cancelled
  'BCF-90': rejected_by_factor
  'BCF-11': registered
*/

const RECEIVED_BY_FACTOR = "Received By Factor";
const CANCELLED = "Cancelled";
const REGISTERED = "Registered";
const REJECTED = "Rejected";

export const CreditorStatusTitles = {
  received_by_factor: RECEIVED_BY_FACTOR,
  cancelled: CANCELLED,
  registered: REGISTERED,
  rejected: REJECTED,
} as const;

export const CreditorDataGridColumns = {
  created_at: "Létrehozva",
  updated_at: "Utoljára módosítva",
  creditor_total_limit: "Limit összege",
  creditor_org: "Creditor cég", // creditor_org_name + creditor_org_tax_number
  store_name: "Store",
} as const;

export const CreditorStatuses: {
  [key in string]: {
    ptStatusCodes: Status[];
    title: string;
  };
} = {
  received_by_factor: {
    ptStatusCodes: ["BCF-10"],
    title: RECEIVED_BY_FACTOR,
  },
  cancelled: {
    ptStatusCodes: ["BCC-11"],
    title: CANCELLED,
  },
  registered: {
    ptStatusCodes: ["BCF-11"],
    title: REGISTERED,
  },
  rejected: {
    ptStatusCodes: ["BCF-90"],
    title: REJECTED,
  },
};
