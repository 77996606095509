
import { defineComponent, PropType } from "vue";
import { StatusCode as ContractStatus } from "@pentech-web/pt-types/contract/status";
import { StatusCode as FundingStatus } from "@pentech-web/pt-types/funding/status";
import {
  ContractStatusCode as BnplContractStatus,
  FundingStatusCode as BnplFundingStatus,
  CreditorStatusCode as BnplCreditorStatus,
  DebtorStatusCode as BnplDebtorStatus,
} from "@pentech-web/pt-types/bnpl/statusCodes";

import store from "@/store";
import DataGrid from "@/components/Table/DataGrid.vue";
import OmniSearch from "@/components/Table/OmniSearch.vue";
import DataLoader from "@/components/DataLoader.vue";
import { ContractStatuses } from "@/utils/consts/contracts";
import { FundingStatuses } from "@/utils/consts/fundings";
import { ContractStatuses as BnplContractStatuses } from "@/utils/consts/bnpl/contracts";
import { FundingStatuses as BnplFundingStatuses } from "@/utils/consts/bnpl/fundings";
import { CreditorStatuses as BnplCreditorStatuses } from "@/utils/consts/bnpl/creditors";
import { DebtorStatuses as BnplDebtorStatuses } from "@/utils/consts/bnpl/debtors";
import { Tables } from "@/utils/types";
import {
  Category,
  formatContractRow,
  formatFundingRow,
  getStatusTitle,
  formatBnplFundingRow,
  formatBnplContractRow,
  StatusCode,
  formatBnplCreditorRow,
  formatBnplDebtorRow,
} from "../utils";

export default defineComponent({
  name: "Table",
  components: { DataGrid, OmniSearch, DataLoader },
  props: {
    activeCategory: {
      type: String as PropType<Category | "orgs">,
      default: "contracts",
    },
    activeStatus: {
      type: String,
      default: "received_by_factor",
    },
  },
  data() {
    return {
      query: "",
    };
  },
  computed: {
    data() {
      const isActiveStatus = (statusCode: StatusCode) => {
        switch (this.activeCategory) {
          case "contracts":
          default:
            return ContractStatuses[this.activeStatus].ptStatusCodes.includes(
              statusCode as ContractStatus
            );
          case "fundings":
            return FundingStatuses[this.activeStatus].ptStatusCodes.includes(
              statusCode as FundingStatus
            );
          case "bnplContracts":
            return BnplContractStatuses[
              this.activeStatus
            ].ptStatusCodes.includes(statusCode as BnplContractStatus);
          case "bnplFundings":
            return BnplFundingStatuses[
              this.activeStatus
            ].ptStatusCodes.includes(statusCode as BnplFundingStatus);
          case "bnplCreditors":
            return BnplCreditorStatuses[
              this.activeStatus
            ].ptStatusCodes.includes(statusCode as BnplCreditorStatus);
          case "bnplDebtors":
            return BnplDebtorStatuses[this.activeStatus].ptStatusCodes.includes(
              statusCode as BnplDebtorStatus
            );
        }
      };

      if (this.activeCategory === "orgs") {
        return store.state.orgs.filter((row: Tables["orgs"]) =>
          `${row.short_name}${row.long_name}${row.tax_number}`
            .toLowerCase()
            .includes(this.query)
        );
      }

      if (this.activeCategory === "contracts") {
        return store.state.contracts
          .filter((row) => isActiveStatus(row.status_code as ContractStatus))
          .map((data) =>
            formatContractRow(data as Tables["vw_contract_with_orgs"])
          )
          .filter(
            (row) =>
              // row.invoice_no?.toLowerCase().includes(this.query) || // TODO
              row.creditor_org?.toLowerCase().includes(this.query) ||
              row.debtor_org?.toLowerCase().includes(this.query)
          );
      }

      if (this.activeCategory === "fundings") {
        return store.state.fundings
          .filter((row) => isActiveStatus(row.status_code as FundingStatus))
          .map((data) =>
            formatFundingRow(data as Tables["vw_funding_with_orgs"])
          )
          .filter(
            (row) =>
              // row.invoice_no?.toLowerCase().includes(this.query) || // TODO
              row.creditor_org?.toLowerCase().includes(this.query) ||
              row.debtor_org?.toLowerCase().includes(this.query)
          );
      }

      if (this.activeCategory === "bnplContracts") {
        return store.state.bnplContracts
          .filter((row) =>
            isActiveStatus(row.status_code as BnplContractStatus)
          )
          .map((data) =>
            formatBnplContractRow(data as Tables["vw_bnpl_contract_with_orgs"])
          )
          .filter(
            (row) =>
              // row.invoice_no?.toLowerCase().includes(this.query) || // TODO
              row.creditor_org?.toLowerCase().includes(this.query) ||
              row.debtor_org?.toLowerCase().includes(this.query)
          );
      }

      if (this.activeCategory === "bnplFundings") {
        return store.state.bnplFundings
          .filter((row) => isActiveStatus(row.status_code as BnplFundingStatus))
          .map((data) =>
            formatBnplFundingRow(data as Tables["vw_bnpl_funding_with_orgs"])
          )
          .filter(
            (row) =>
              // row.invoice_no?.toLowerCase().includes(this.query) || // TODO
              row.creditor_org?.toLowerCase().includes(this.query) ||
              row.debtor_org?.toLowerCase().includes(this.query)
          );
      }

      if (this.activeCategory === "bnplCreditors") {
        return store.state.bnplCreditors
          .filter((row) =>
            isActiveStatus(row.status_code as BnplCreditorStatus)
          )
          .map((data) =>
            formatBnplCreditorRow(data as Tables["vw_bnpl_creditor_with_org"])
          )
          .filter((row) =>
            // row.invoice_no?.toLowerCase().includes(this.query) || // TODO
            row.creditor_org?.toLowerCase().includes(this.query)
          );
      }

      return store.state.bnplDebtors
        .filter((row) => isActiveStatus(row.status_code as BnplDebtorStatus))
        .map((data) =>
          formatBnplDebtorRow(data as Tables["vw_bnpl_debtor_with_org"])
        )
        .filter((row) =>
          // row.invoice_no?.toLowerCase().includes(this.query) || // TODO
          row.debtor_org?.toLowerCase().includes(this.query)
        );
    },
  },
  methods: {
    search(value: string) {
      this.query = value;
    },
    getStatusTitle,
  },
});
