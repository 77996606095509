<template>
  <section class="section">
    <div class="container">
      <section class="hero is-danger">
        <div class="hero-body">
          <p class="title">Hiba történt, de dolgozunk rajta</p>
          <p class="subtitle">Kérjük, látogass vissza később!</p>
        </div>
      </section>
    </div>
  </section>
</template>
