import { StatusCode as Status } from "@pentech-web/pt-types/contract/status";

const RECEIVED_BY_FACTOR = "Received By Factor";
const PENDING_FACTOR = "Pending Factor";
const ACCEPTED_BY_FACTOR = "Accepted by Factor";
const CONTRACT_GENERATED = "Contract Generated";
const CONTRACT_SIGNED = "Contract Signed";
const REJECTED = "Rejected";

export const ContractStatusTitles = {
  received_by_factor: RECEIVED_BY_FACTOR,
  pending_factor: PENDING_FACTOR,
  accepted_by_factor: ACCEPTED_BY_FACTOR,
  contract_generated: CONTRACT_GENERATED,
  contract_signed: CONTRACT_SIGNED,
  rejected: REJECTED,
} as const;

export const ContractDocTitles = {
  trialBalance: "Főkönyvi kivonat",
  taxAccountStatement: "NAV adófolyószámla kivonat",
  specimenSignature: "Aláírási címpéldány",
  shareholdersAgreement: "Társasági szerződés",
  memberRegister: "Tagjegyzék",
  bankAccountStatement: "Bankszámla kivonat",
  salesLedger: "Vevő analitika",
  contract: "Megbízási szerződés",
  khrCreditor: "KHR Szállító információ",
  khrDebtor: "KHR Vevő információ",
  debtCollection: "Inkasszó",
} as const;

export const ContractDataGridColumns = {
  created_at: "Létrehozva",
  updated_at: "Utoljára módosítva",
  total_limit: "Limit összege",
  creditor_org: "Creditor cég", // creditor_org_name + creditor_org_tax_number
  debtor_org: "Debtor cég", // debtor_org_name + debtor_org_tax_number
} as const;

export const ContractStatuses: {
  [key in string]: {
    ptStatusCodes: Status[];
    title: string;
  };
} = {
  received_by_factor: {
    ptStatusCodes: ["CF-10"],
    title: RECEIVED_BY_FACTOR,
  },
  pending_factor: {
    ptStatusCodes: ["CF-11"],
    title: PENDING_FACTOR,
  },
  accepted_by_factor: {
    ptStatusCodes: ["CF-12"],
    title: ACCEPTED_BY_FACTOR,
  },
  contract_generated: {
    ptStatusCodes: ["CP-13"],
    title: CONTRACT_GENERATED,
  },
  contract_signed: {
    ptStatusCodes: ["CU-13"],
    title: CONTRACT_SIGNED,
  },
  rejected: {
    ptStatusCodes: [
      "CF-90",
      "CF-91",
      "CU-94",
      "CU-95",
      "CD-90",
      "CP-92",
      "CP-93",
    ],
    title: REJECTED,
  },
};
