/* eslint-disable camelcase */
import hash from "object-hash";

// eslint-disable-next-line import/no-cycle
import { postgrest } from "./httpsEndpoints";
import { JsonObject } from "./types";

type ErrorCode =
  | "postgrest/adding_balance_failed"
  | "postgrest/getting_source_balances_failed"
  | "postgrest/adding_transaction_failed"
  | "postgrest/deleting_balance_failed"
  | "postgrest/loading_data_failed"
  | "file_upload_failure"
  | "navigation_failure"
  | "auth/login_failure"
  | "set_status/user_cant_approve"
  | "set_status/user_already_approved"
  | "set_status_failure"
  | "file_open/not_found"
  | "file_open_failure"
  | "postgrest/getting_balance_or_transactions_failed"
  | "postgrest/getting_balances_failed";

export const systemLog = async (
  entry:
    | {
        message: string;
        severity: "INFO" | "DEBUG";
        payload: JsonObject;
        userId?: string | null;
      }
    | {
        error_code: ErrorCode;
        message: string;
        severity: "ERROR";
        payload: JsonObject;
        userId?: string | null;
      },
  authToken = sessionStorage.getItem("auth-token")
): Promise<void> => {
  const {
    message,
    severity,
    payload,
    userId = sessionStorage.getItem("user-id"),
  } = entry;

  const loginId = hash.sha1(authToken);

  await postgrest
    .auth(authToken || "")
    .from("system_log")
    .insert({
      error_code: severity === "ERROR" ? entry.error_code : undefined,
      message,
      severity,
      service: "frontend",
      payload,
      user_id: userId,
      login_id: loginId,
    });
};

export const logInfo = (message: string, payload: JsonObject): Promise<void> =>
  systemLog(
    {
      message,
      severity: "INFO",
      payload,
      userId: sessionStorage.getItem("user-id"),
    },
    sessionStorage.getItem("auth-token")
  );

export const logDebug = (message: string, payload: JsonObject): Promise<void> =>
  systemLog(
    {
      message,
      severity: "DEBUG",
      payload,
      userId: sessionStorage.getItem("user-id"),
    },
    sessionStorage.getItem("auth-token")
  );

export const logError = (
  error_code: ErrorCode,
  message: string,
  payload: JsonObject
): Promise<void> =>
  systemLog(
    {
      error_code,
      message,
      severity: "ERROR",
      payload,
      userId: sessionStorage.getItem("user-id"),
    },
    sessionStorage.getItem("auth-token")
  );
