
import { defineComponent, PropType } from "vue";
import { ContractDocTitles } from "@/utils/consts/contracts";
import { FundingDocTitles } from "@/utils/consts/fundings";
import { FundingDocTitles as BnplFundingDocTitles } from "@/utils/consts/bnpl/fundings";
import { Category, formatTime, openFile } from "@/utils";
import { getUploadedDocuments } from "@/utils/httpsEndpoints";
import { Tables } from "@/utils/types";
import { logDebug, logError } from "@/utils/systemLog";

export default defineComponent({
  name: "RowDocuments",
  props: {
    activeCategory: {
      type: String as PropType<Category>,
      default: "contracts",
    },
    selectedRow: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      uploadedDocuments: [],
    } as {
      uploadedDocuments: Tables["uploaded_documents"][];
    };
  },
  computed: {
    contractDocuments() {
      return ContractDocTitles;
    },
    fundingDocuments() {
      return FundingDocTitles;
    },
    bnplFundingDocuments() {
      return BnplFundingDocTitles;
    },
  },
  async mounted() {
    await logDebug("Loading data", {
      entities: ["uploaded_documents"],
    });

    const { data: uploadedDocuments } = await getUploadedDocuments(
      this.activeCategory as "contracts" | "fundings",
      this.selectedRow
    );

    if (!uploadedDocuments) {
      await logError("postgrest/loading_data_failed", "", {
        entities: ["uploaded_documents"],
      });

      throw Error(`Unable to load uploadedDocuments`);
    }

    this.uploadedDocuments = uploadedDocuments;
  },
  methods: {
    open(categoryFilter: string) {
      this.$emit("open", categoryFilter);
    },
    formatTime,
    openFile,
  },
});
