import { ContractStatusCode as Status } from "@pentech-web/pt-types/bnpl/statusCodes";

/*
  'BRC-10': created
  'BRP-10': upserto_to_factor

  'BRF-10': received_by_factor
  'BRF-90': rejected_by_factor
  'BRF-11': accepted
*/

const RECEIVED_BY_FACTOR = "Received By Factor";
const ACCEPTED = "Accepted";
const REJECTED_BY_FACTOR = "Rejected By Factor";

export const ContractStatusTitles = {
  received_by_factor: RECEIVED_BY_FACTOR,
  accepted: ACCEPTED,
  rejected_by_factor: REJECTED_BY_FACTOR,
} as const;

export const ContractDataGridColumns = {
  created_at: "Létrehozva",
  updated_at: "Utoljára módosítva",
  creditor_org: "Creditor cég", // creditor_org_name + creditor_org_tax_number
  debtor_org: "Debtor cég", // debtor_org_name + debtor_org_tax_number
} as const;

export const ContractStatuses: {
  [key in string]: {
    ptStatusCodes: Status[];
    title: string;
  };
} = {
  received_by_factor: {
    ptStatusCodes: ["BRF-10"],
    title: RECEIVED_BY_FACTOR,
  },
  accepted: {
    ptStatusCodes: ["BRF-11"],
    title: ACCEPTED,
  },
  rejected_by_factor: {
    ptStatusCodes: ["BRF-90"],
    title: REJECTED_BY_FACTOR,
  },
};
