import { StatusCode as Status } from "@pentech-web/pt-types/funding/status";

const RECEIVED_BY_FACTOR = "Received By Factor";
const PENDING_PAYOUT = "Pending Payout";
const PAID_ADVANCE_AMOUNT = "Paid Advance Amount";
const ADVANCE_PAYOUT_CONFIRMED = "Advance Payout Confirmed";
const DEBTOR_PAYBACK = "Debtor Payback";
const PENDING_REMAINDER_PAYOUT = "Pending Remainder Payout";
const PAID_REMAINDER = "Paid Remainder";
const REMAINDER_PAYOUT_CONFIRMED = "Remainder Payout Confirmed";
const REJECTED_BY_FACTOR = "Rejected By Factor";
const PAYMENT_UNSUCCESSFUL = "Payment Unsuccessful";
const REMAINDER_PAYMENT_UNSUCCESSFUL = "Remainder Payment Unsuccessful";
const COLLECTION = "Collection";

export const FundingStatusTitles = {
  received_by_factor: RECEIVED_BY_FACTOR,
  pending_payout: PENDING_PAYOUT,
  paid_advance_amount: PAID_ADVANCE_AMOUNT,
  advance_payout_confirmed: ADVANCE_PAYOUT_CONFIRMED,
  debtor_payback: DEBTOR_PAYBACK,
  pending_remainder_payout: PENDING_REMAINDER_PAYOUT,
  paid_remainder: PAID_REMAINDER,
  remainder_payout_confirmed: REMAINDER_PAYOUT_CONFIRMED,
  rejected_by_factor: REJECTED_BY_FACTOR,
  payment_unsuccessful: PAYMENT_UNSUCCESSFUL,
  remainder_payment_unsuccessful: REMAINDER_PAYMENT_UNSUCCESSFUL,
  collection: COLLECTION,
} as const;

export const FundingDocTitles = {
  certOfDelivery: "Teljesítési igazolás",
  invoice: "Számla",
  debtorConfirmation: "Vevői visszaigazolás",
} as const;

export const FundingDataGridColumns = {
  created_at: "Létrehozva",
  updated_at: "Utoljára módosítva",
  amount: "Igénylés összege",
  issue_date: "Kiállítás dátuma",
  due_date: "Lejárat dátuma",
  invoice_no: "Számla sorszáma",
  refinance_type: "Refinance type",
  refinanced_at: "Refinanced at",
  creditor_org: "Creditor cég", // creditor_org_name + creditor_org_tax_number
  debtor_org: "Debtor cég", // debtor_org_name + debtor_org_tax_number
} as const;

export const FundingDetailsColumns = {
  created_at: "Létrehozva",
  updated_at: "Utoljára módosítva",
  amount: "Igénylés összege",
  invoice_amount: "Számla összege",
  issue_date: "Kiállítás dátuma",
  due_date: "Lejárat dátuma",
  invoice_no: "Számla sorszáma",
  refinance_type: "Refinance type",
  refinanced_at: "Refinanced at",
  creditor_org: "Creditor cég", // creditor_org_name + creditor_org_tax_number
  debtor_org: "Debtor cég", // debtor_org_name + debtor_org_tax_number
} as const;

export const FundingStatuses: {
  [key in string]: {
    ptStatusCodes: Status[];
    title: string;
  };
} = {
  received_by_factor: {
    ptStatusCodes: ["FF-10"],
    title: RECEIVED_BY_FACTOR,
  },
  pending_payout: {
    ptStatusCodes: ["FF-11"],
    title: PENDING_PAYOUT,
  },
  paid_advance_amount: {
    ptStatusCodes: ["FF-12"],
    title: PAID_ADVANCE_AMOUNT,
  },
  advance_payout_confirmed: {
    ptStatusCodes: ["FF-13"],
    title: ADVANCE_PAYOUT_CONFIRMED,
  },
  debtor_payback: {
    ptStatusCodes: ["FF-14"],
    title: DEBTOR_PAYBACK,
  },
  pending_remainder_payout: {
    ptStatusCodes: ["FF-15"],
    title: PENDING_REMAINDER_PAYOUT,
  },
  paid_remainder: {
    ptStatusCodes: ["FF-16"],
    title: PAID_REMAINDER,
  },
  remainder_payout_confirmed: {
    ptStatusCodes: ["FF-17"],
    title: REMAINDER_PAYOUT_CONFIRMED,
  },
  rejected_by_factor: {
    ptStatusCodes: ["FF-90"],
    title: REJECTED_BY_FACTOR,
  },
  payment_unsuccessful: {
    ptStatusCodes: ["FF-91"],
    title: PAYMENT_UNSUCCESSFUL,
  },
  remainder_payment_unsuccessful: {
    ptStatusCodes: ["FF-92"],
    title: REMAINDER_PAYMENT_UNSUCCESSFUL,
  },
  collection: {
    ptStatusCodes: ["FP-20", "FP-21", "FP-22", "FP-23"],
    title: COLLECTION,
  },
};
