/* eslint-disable no-use-before-define */

const WARNING_MESSAGE =
  "Automatikus kijelentkeztetés 10 perc inaktivitás miatt!";
const IDLE_TIMEOUT = 1000 * 60 * 10;

(() => {
  const createTimer = () =>
    setTimeout(() => {
      // eslint-disable-next-line no-alert
      alert(WARNING_MESSAGE);

      window.removeEventListener("mousemove", listener);
      window.removeEventListener("click", listener);
      window.removeEventListener("keydown", listener);

      if (process.env.NODE_ENV !== "development") {
        sessionStorage.setItem("auth-token", "");
        window.location.reload();
      }
    }, IDLE_TIMEOUT);

  const listener = () => {
    clearTimeout(timer);
    timer = createTimer();

    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.log("tick");
    }
  };

  let timer = createTimer();

  window.addEventListener("mousemove", listener);
  window.addEventListener("click", listener);
  window.addEventListener("keydown", listener);
})();
