import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4eee84ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  class: "has-tooltip-arrow",
  "data-tooltip": "Csak az eredetileg csatolt dokumentumok kerülnek megnyitásra, az utólag feltöltöttek nem."
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeCategory === 'contracts'
        ? _ctx.contractDocuments
        : _ctx.activeCategory === 'fundings'
        ? _ctx.fundingDocuments
        : _ctx.bnplFundingDocuments, (documentTitle, documentType) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: documentType }, [
        _createElementVNode("p", null, [
          _createElementVNode("button", {
            class: "button mb-5",
            onClick: ($event: any) => (_ctx.open(documentType))
          }, _toDisplayString(documentTitle) + " megnyitása ", 9, _hoisted_1)
        ]),
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uploadedDocuments, (uploadedDocument, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (documentType === uploadedDocument.category)
                ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
                    _createElementVNode("button", {
                      class: "button is-text",
                      onClick: ($event: any) => (_ctx.openFile(uploadedDocument.file_path))
                    }, " Utólag feltöltve: " + _toDisplayString(_ctx.formatTime(uploadedDocument.created_at)), 9, _hoisted_3)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ])
      ], 64))
    }), 128)),
    _createElementVNode("span", _hoisted_4, [
      _createElementVNode("button", {
        class: "button mb-5 is-primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open('')))
      }, " Minden dokumentum megnyitása ")
    ])
  ]))
}