
import { defineComponent } from "vue";

import { ContractStatusTitles } from "@/utils/consts/contracts";
import { FundingStatusTitles } from "@/utils/consts/fundings";
import { ContractStatusTitles as BnplContractStatusTitles } from "@/utils/consts/bnpl/contracts";
import { FundingStatusTitles as BnplFundingStatusTitles } from "@/utils/consts/bnpl/fundings";
import { CreditorStatusTitles } from "@/utils/consts/bnpl/creditors";
import { DebtorStatusTitles } from "@/utils/consts/bnpl/debtors";

export default defineComponent({
  name: "Sidebar",
  data() {
    return {
      contractsOpen: true,
      fundingsOpen: false,
      bnplContractsOpen: false,
      bnplFundingsOpen: false,
      bnplCreditorsOpen: false,
      bnplDebtorsOpen: false,
      othersOpen: false,
    };
  },
  computed: {
    activePath() {
      return this.$route.path;
    },
    contractStatusTitles() {
      return ContractStatusTitles;
    },
    fundingStatusTitles() {
      return FundingStatusTitles;
    },
    bnplContractStatusTitles() {
      return BnplContractStatusTitles;
    },
    bnplFundingStatusTitles() {
      return BnplFundingStatusTitles;
    },
    creditorStatusTitles() {
      return CreditorStatusTitles;
    },
    debtorStatusTitles() {
      return DebtorStatusTitles;
    },
  },
  // TODO move the logout button to the App component to leave Sidebar presentational
  methods: {
    logout() {
      sessionStorage.setItem("auth-token", "");
      this.$router.push({ name: "Login" });
    },
    openSidebarGroup(group: string) {
      this.contractsOpen = false;
      this.fundingsOpen = false;
      this.bnplContractsOpen = false;
      this.bnplFundingsOpen = false;
      this.bnplCreditorsOpen = false;
      this.bnplDebtorsOpen = false;
      this.othersOpen = false;

      switch (group) {
        case "contracts":
        default:
          this.contractsOpen = true;
          break;

        case "fundings":
          this.fundingsOpen = true;
          break;

        case "bnplContracts":
          this.bnplContractsOpen = true;
          break;

        case "bnplFundings":
          this.bnplFundingsOpen = true;
          break;

        case "bnplCreditors":
          this.bnplCreditorsOpen = true;
          break;

        case "bnplDebtors":
          this.bnplDebtorsOpen = true;
          break;

        case "others":
          this.othersOpen = true;
          break;
      }
    },
  },
});
