
import { defineComponent } from "vue";
import { auth } from "@/utils/httpsEndpoints";
import Notification from "@/components/Notification.vue";
import { logError } from "@/utils/systemLog";

export default defineComponent({
  name: "Login",
  components: { Notification },
  data() {
    return {
      email: "",
      password: "",
      error: false,
      loading: false,
    };
  },
  methods: {
    async login() {
      this.error = false;
      this.loading = true;

      try {
        const {
          data: { token, id, canApprove },
        } = await auth(this.email, this.password);

        sessionStorage.setItem("auth-token", token);
        sessionStorage.setItem("user-id", id);
        sessionStorage.setItem("user-can-approve", `${canApprove}`);

        this.$router.push({ name: "Index" });
      } catch (e) {
        this.error = true;

        await logError(
          "auth/login_failure",
          `${(e as Error).name}: ${(e as Error).message}`,
          {
            error: JSON.stringify(e),
          }
        );
      } finally {
        this.loading = false;
      }
    },
  },
});
