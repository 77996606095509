/* eslint-disable camelcase */
import {
  PostgrestClient,
  PostgrestFilterBuilder as DbTypes,
} from "@supabase/postgrest-js";
import dayjs from "dayjs";
import { snakeCase } from "snake-case";

import { op } from "@pentech-web/pt-types/db/postgrest";
import {
  OrgDocCategory,
  ContractDocCategory,
} from "@pentech-web/pt-types/funding";

import { SupportedCurrency } from "@/utils/consts/currencies";
import { httpsApi } from "./httpsApi";
import { Functions, Tables, TransactionWithSourceBalance } from "./types";
// eslint-disable-next-line import/no-cycle
import { Category, StatusCode } from ".";
// eslint-disable-next-line import/no-cycle
import { systemLog } from "./systemLog";

const POSTGREST_BASE_URL =
  process.env.VUE_APP_POSTGREST_BASE_URL ||
  `https://${window.location.host}/pg`;

export const postgrest = new PostgrestClient(POSTGREST_BASE_URL);

export const auth = async (
  email: string,
  password: string
): Promise<{
  status: "ok";
  data: {
    token: string;
    id: string;
    canApprove: boolean;
  };
}> => {
  const result = await httpsApi({
    endpoint: "auth",
    payload: {
      email,
      password,
    },
  });

  await systemLog(
    {
      message: "Logged in",
      severity: "INFO",
      payload: {},
      userId: result.data.id,
    },
    result.data.token
  );

  return result;
};

type Entity =
  | "vw_contract_with_orgs"
  | "vw_bnpl_contract_with_orgs"
  | "vw_funding_with_orgs"
  | "vw_bnpl_funding_with_orgs"
  | "orgs"
  | "transaction_subjects"
  | "vw_bnpl_creditor_with_org"
  | "vw_bnpl_debtor_with_org"
  | "contract_meta"
  | "bnpl_creditor_meta";

export const getEntity = <E extends Entity>(entity: E): DbTypes<Tables[E]> => {
  return postgrest
    .auth(sessionStorage.getItem("auth-token") || "")
    .from(entity)
    .select()
    .eq("deleted", false);
};

export const getContract = async (
  id: string
): Promise<Tables["vw_contract_with_orgs"] | null> =>
  (
    await postgrest
      .auth(sessionStorage.getItem("auth-token") || "")
      .from<Tables["vw_contract_with_orgs"]>("vw_contract_with_orgs")
      .select()
      .eq("id", id)
      .single()
  ).data;

export const getFunding = async (
  id: string
): Promise<Tables["vw_funding_with_orgs"] | null> =>
  (
    await postgrest
      .auth(sessionStorage.getItem("auth-token") || "")
      .from<Tables["vw_funding_with_orgs"]>("vw_funding_with_orgs")
      .select()
      .eq("id", id)
      .single()
  ).data;

export const getBalance = async (
  balanceId: string
): Promise<Tables["vw_balances"] | null> => {
  const result = await postgrest
    .auth(sessionStorage.getItem("auth-token") || "")
    .from<Tables["vw_balances"]>("vw_balances")
    .select()
    .eq("id", balanceId)
    .single();
  if (result) {
    return result.data;
  }
  // eslint-disable-next-line no-console
  console.error(`Unable to find Balance for id: "${balanceId}"`);
  return null;
};

export const getBalances = (
  orgId: string,
  includeDeleted = false
): DbTypes<Tables["vw_balances"]> => {
  const query = postgrest
    .auth(sessionStorage.getItem("auth-token") || "")
    .from<Tables["vw_balances"]>("vw_balances")
    .select()
    .eq("org_id", orgId);
  if (includeDeleted === false) {
    return query.eq("deleted", false);
  }
  return query;
};

export const getSourceBalances = (): DbTypes<Tables["balances"]> =>
  postgrest
    .auth(sessionStorage.getItem("auth-token") || "")
    .from<Tables["balances"]>("balances")
    .select()
    .eq("deleted", false)
    .eq("is_source", true);

export const addBalance = (
  orgId: string,
  currency: SupportedCurrency,
  title: string | null,
  isSource: boolean
): DbTypes<Tables["balances"]> =>
  postgrest
    .auth(sessionStorage.getItem("auth-token") || "")
    .from<Tables["balances"]>("balances")
    .insert({ org_id: orgId, currency, title, is_source: isSource });

export const deleteBalance = (balanceId: string): DbTypes<Tables["balances"]> =>
  postgrest
    .auth(sessionStorage.getItem("auth-token") || "")
    .from<Tables["balances"]>("balances")
    .update({ deleted: true })
    .match({ id: balanceId });

export const getTransactions = async (
  balanceId: string
): Promise<TransactionWithSourceBalance[] | null> => {
  return (
    await postgrest
      .auth(sessionStorage.getItem("auth-token") || "")
      .from<TransactionWithSourceBalance>("transactions")
      .select(
        `*, 
        transactions(
          id,
          balances!transactions_balance_id_fkey( 
            id,
            orgs( 
              short_name
            )
          )
        )`
      )
      .eq("deleted", false)
      .eq("balance_id", balanceId)
      .order("created_at", { ascending: false })
  ).data;
};

export const addTransaction = ({
  balance_id,
  currency,
  amount,
  vat_rate,
  subject,
  comment = null,
  contract_id = null,
  funding_id = null,
  bnpl_contract_id = null,
  bnpl_funding_id = null,
  source_balance_id = null,
}: Functions["add_transaction"]): DbTypes<Tables["transactions"]> =>
  postgrest
    .auth(sessionStorage.getItem("auth-token") || "")
    .rpc("add_transaction", {
      balance_id,
      currency,
      amount,
      vat_rate,
      subject,
      comment,
      contract_id,
      funding_id,
      bnpl_contract_id,
      bnpl_funding_id,
      source_balance_id,
    });

export const setStatus = async (
  category: Category,
  id: string,
  statusCode: StatusCode,
  dateOfProcessing: string
): Promise<DbTypes<Tables["contracts"] | Tables["fundings"]>> => {
  const resource = snakeCase(category); // bnplContracts => bnpl_contracts, etc.

  await httpsApi({
    endpoint: `pentech/${resource}/${id}/status`,
    method: "PUT",
    payload: {
      statusCode,
      dateOfProcessing,
    },
  });

  // TODO find out why is it necessary, when the endpoint called above also changes the local entity's status?
  return postgrest
    .auth(sessionStorage.getItem("auth-token") || "")
    .from<Tables["contracts"] | Tables["fundings"]>(resource) // TODO check typing
    .update({ status_code: statusCode })
    .match({ id });
};

// TODO ?
export const getDocumentUrls = async (
  category: Category,
  id: string
): Promise<DbTypes<Tables["docs"]>> => {
  if (category === "fundings") {
    return postgrest
      .auth(sessionStorage.getItem("auth-token") || "")
      .from<Tables["docs"]>("docs")
      .select()
      .eq("funding_id", id);
  }

  const { data } = await postgrest
    .auth(sessionStorage.getItem("auth-token") || "")
    .from<Tables["contracts"]>("contracts")
    .select("creditor_org_id")
    .eq("id", id)
    .single();

  if (!data) {
    throw new Error("Creditor not found.");
  }

  const orgId = data.creditor_org_id;

  return postgrest
    .auth(sessionStorage.getItem("auth-token") || "")
    .from<Tables["docs"]>("docs")
    .select()
    .eq("org_id", orgId)
    .or(
      `category.${op.in(OrgDocCategory)}, ${op.and([
        `category.${op.in(ContractDocCategory)}`,
        `contract_id.eq.${id}`,
      ])}`
    )
    .or(`expires.is.null, expires.${op.gte(dayjs().format("YYYY-MM-DD"))}`);
};

export const getUploadedDocuments = async (
  category: "contracts" | "fundings",
  id: string
): Promise<DbTypes<Tables["uploaded_documents"]>> => {
  if (category === "contracts") {
    return postgrest
      .auth(sessionStorage.getItem("auth-token") || "")
      .from<Tables["uploaded_documents"]>("uploaded_documents")
      .select()
      .eq("contract_id", id)
      .eq("deleted", false);
  }

  return postgrest
    .auth(sessionStorage.getItem("auth-token") || "")
    .from<Tables["uploaded_documents"]>("uploaded_documents")
    .select()
    .eq("funding_id", id)
    .eq("deleted", false);
};
