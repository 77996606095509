
import { defineComponent } from "vue";

import { addBalance } from "@/utils/httpsEndpoints";
import { logError } from "@/utils/systemLog";
import {
  SupportedCurrencies,
  SupportedCurrency,
} from "@/utils/consts/currencies";

export default defineComponent({
  name: "CreateBalanceModal",
  props: {
    isOpen: { type: Boolean, required: true },
    orgId: { type: String, required: true },
  },
  emits: ["close"],
  data() {
    return {
      SupportedCurrencies,
      isLoading: false,
      currency: "HUF" as SupportedCurrency,
      title: "",
      isSource: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async submit() {
      try {
        this.isLoading = true;

        await addBalance(
          this.$props.orgId,
          this.currency,
          this.title || null,
          this.isSource
        );

        this.close();

        this.isLoading = false;
      } catch (e) {
        await logError(
          "postgrest/adding_balance_failed",
          `${(e as Error).name}: ${(e as Error).message}`,
          {
            orgId: this.orgId,
            error: JSON.stringify(e),
          }
        );

        // eslint-disable-next-line no-alert
        alert("Error: Adding balance"); // TODO use component-based error display
      }
    },
  },
});
