
import { defineComponent, PropType } from "vue";

import ContractFlow from "@/components/Row/Flows/Contract.vue";
import FundingFlow from "@/components/Row/Flows/Funding.vue";
import BnplContractFlow from "@/components/Row/Flows/BnplContract.vue";
import BnplFundingFlow from "@/components/Row/Flows/BnplFunding.vue";
import BnplCreditorFlow from "@/components/Row/Flows/BnplCreditor.vue";
import BnplDebtorFlow from "@/components/Row/Flows/BnplDebtor.vue";
import { getToday, StatusCode } from "@/utils";

export default defineComponent({
  name: "RowFlow",
  components: {
    ContractFlow,
    FundingFlow,
    BnplContractFlow,
    BnplFundingFlow,
    BnplCreditorFlow,
    BnplDebtorFlow,
  },

  props: {
    currentStatusCode: {
      type: String as PropType<StatusCode>,
      required: true,
    },
    activeCategory: {
      type: String,
      default: "contracts",
    },
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      dateOfProcessing: getToday(),
    };
  },

  methods: {
    update(statusCode: StatusCode) {
      this.$emit("update", {
        statusCode,
        dateOfProcessing: this.dateOfProcessing,
      });
    },
  },
});
