
import { defineComponent, PropType } from "vue";
import { CreditorStatusCode as StatusCode } from "@pentech-web/pt-types/bnpl/statusCodes";

export default defineComponent({
  name: "BnplCreditorFlow",
  props: {
    currentStatusCode: {
      type: String as PropType<StatusCode>,
      required: true,
    },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    update(statusCode: StatusCode) {
      this.$emit("update", statusCode);
    },
  },
});
