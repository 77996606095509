import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d6a3f34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box" }
const _hoisted_2 = {
  key: 0,
  class: "field has-addons"
}
const _hoisted_3 = { class: "control button-container-1-2" }
const _hoisted_4 = { class: "control button-container-1-2" }
const _hoisted_5 = {
  key: 1,
  class: "field has-addons"
}
const _hoisted_6 = { class: "control button-container-1-2" }
const _hoisted_7 = { class: "control button-container-1-2" }
const _hoisted_8 = {
  key: 2,
  class: "field has-addons"
}
const _hoisted_9 = { class: "control button-container-1-2" }
const _hoisted_10 = { class: "control button-container-1-2" }
const _hoisted_11 = {
  key: 3,
  class: "field has-addons"
}
const _hoisted_12 = { class: "control button-container-1-1" }
const _hoisted_13 = {
  key: 4,
  class: "field has-addons"
}
const _hoisted_14 = { class: "control button-container-1-2" }
const _hoisted_15 = { class: "control button-container-1-2" }
const _hoisted_16 = {
  key: 5,
  class: "field has-addons"
}
const _hoisted_17 = { class: "control button-container-1-1" }
const _hoisted_18 = {
  key: 6,
  class: "field has-addons"
}
const _hoisted_19 = { class: "control button-container-1-1" }
const _hoisted_20 = {
  key: 7,
  class: "field has-addons"
}
const _hoisted_21 = { class: "control button-container-1-2" }
const _hoisted_22 = { class: "control button-container-1-2" }
const _hoisted_23 = {
  key: 8,
  class: "field has-addons"
}
const _hoisted_24 = { class: "control button-container-1-1" }
const _hoisted_25 = { key: 9 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currentStatusCode === 'FF-10')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, [
            _createElementVNode("button", {
              class: "button is-danger is-fullwidth has-tooltip-arrow",
              "data-tooltip": "FF-90",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.update('FF-90')))
            }, " Elutasítás ")
          ]),
          _createElementVNode("p", _hoisted_4, [
            _createElementVNode("button", {
              class: "button is-success is-fullwidth has-tooltip-arrow",
              "data-tooltip": "FF-11",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.update('FF-11')))
            }, " Elfogadás ")
          ])
        ]))
      : (_ctx.currentStatusCode === 'FF-11')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, [
              _createElementVNode("button", {
                class: "button is-danger is-fullwidth has-tooltip-arrow",
                "data-tooltip": "FF-90",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.update('FF-90')))
              }, " Elutasítás ")
            ]),
            _createElementVNode("p", _hoisted_7, [
              _createElementVNode("button", {
                class: "button is-success is-fullwidth has-tooltip-arrow",
                "data-tooltip": "FF-12",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.update('FF-12')))
              }, " Előleg elutalva ")
            ])
          ]))
        : (_ctx.currentStatusCode === 'FF-12')
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, [
                _createElementVNode("button", {
                  class: "button is-danger is-fullwidth has-tooltip-arrow",
                  "data-tooltip": "FF-91",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.update('FF-91')))
                }, " Elutasítás ")
              ]),
              _createElementVNode("p", _hoisted_10, [
                _createElementVNode("button", {
                  class: "button is-success is-fullwidth has-tooltip-arrow",
                  "data-tooltip": "FF-13",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.update('FF-13')))
                }, " Előleg utalás megerősítve ")
              ])
            ]))
          : (_ctx.currentStatusCode === 'FF-91')
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("p", _hoisted_12, [
                  _createElementVNode("button", {
                    class: "button is-success is-fullwidth has-tooltip-arrow",
                    "data-tooltip": "FF-12",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.update('FF-12')))
                  }, " Elfogadás ")
                ])
              ]))
            : (_ctx.currentStatusCode === 'FF-13')
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("p", _hoisted_14, [
                    _createElementVNode("button", {
                      class: "button is-warning is-fullwidth has-tooltip-arrow",
                      "data-tooltip": "FP-20",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.update('FP-20')))
                    }, " Behajtás ")
                  ]),
                  _createElementVNode("p", _hoisted_15, [
                    _createElementVNode("button", {
                      class: "button is-success is-fullwidth has-tooltip-arrow",
                      "data-tooltip": "FF-14",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.update('FF-14')))
                    }, " Vevő által fizetve ")
                  ])
                ]))
              : (_ctx.currentStatusCode === 'FF-14')
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createElementVNode("p", _hoisted_17, [
                      _createElementVNode("button", {
                        class: "button is-success is-fullwidth has-tooltip-arrow",
                        "data-tooltip": "FF-15",
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.update('FF-15')))
                      }, " Maradványérték kifizetése ")
                    ])
                  ]))
                : (_ctx.currentStatusCode === 'FF-15')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _createElementVNode("p", _hoisted_19, [
                        _createElementVNode("button", {
                          class: "button is-success is-fullwidth has-tooltip-arrow",
                          "data-tooltip": "FF-16",
                          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.update('FF-16')))
                        }, " Utalva ")
                      ])
                    ]))
                  : (_ctx.currentStatusCode === 'FF-16')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                        _createElementVNode("p", _hoisted_21, [
                          _createElementVNode("button", {
                            class: "button is-danger is-fullwidth has-tooltip-arrow",
                            "data-tooltip": "FF-92",
                            onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.update('FF-92')))
                          }, " Elutasítás ")
                        ]),
                        _createElementVNode("p", _hoisted_22, [
                          _createElementVNode("button", {
                            class: "button is-success is-fullwidth has-tooltip-arrow",
                            "data-tooltip": "FF-17",
                            onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.update('FF-17')))
                          }, " Maradványérték utalás megerősítve ")
                        ])
                      ]))
                    : (_ctx.currentStatusCode === 'FF-92')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                          _createElementVNode("p", _hoisted_24, [
                            _createElementVNode("button", {
                              class: "button is-success is-fullwidth has-tooltip-arrow",
                              "data-tooltip": "FF-16",
                              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.update('FF-16')))
                            }, " Elfogadás ")
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_25, "Nincsen(ek) lehetőség(ek)!"))
  ]))
}