import { DebtorStatusCode as Status } from "@pentech-web/pt-types/bnpl/statusCodes";

/*
  'BDC-10': created
  'BDP-10': upsert_to_factor
  'BDP-90': auto_ko
  'BDP-91': limit_rejected

  'BDF-10': received_by_factor
  'BDF-90': rejected_by_factor
  'BDF-11': registered
*/

const RECEIVED_BY_FACTOR = "Received By Factor";
const REGISTERED = "Registered";
const REJECTED = "Rejected";

export const DebtorStatusTitles = {
  received_by_factor: RECEIVED_BY_FACTOR,
  registered: REGISTERED,
  rejected: REJECTED,
} as const;

export const DebtorDataGridColumns = {
  created_at: "Létrehozva",
  updated_at: "Utoljára módosítva",
  debtor_total_limit: "Limit összege",
  debtor_org: "Debtor cég", // debtor_org_name + debtor_org_tax_number
} as const;

export const DebtorStatuses: {
  [key in string]: {
    ptStatusCodes: Status[];
    title: string;
  };
} = {
  received_by_factor: {
    ptStatusCodes: ["BDF-10"],
    title: RECEIVED_BY_FACTOR,
  },
  registered: {
    ptStatusCodes: ["BDF-11"],
    title: REGISTERED,
  },
  rejected: {
    ptStatusCodes: ["BDF-90"],
    title: REJECTED,
  },
};
