import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49f7ca8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tag is-info is-light"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[_ctx.label ? 'tags has-addons' : ''], "tag-container"])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("span", {
      class: _normalizeClass(["tag has-text-weight-semibold", _ctx.tagClass])
    }, _toDisplayString(_ctx.displayedAmount), 3)
  ], 2))
}